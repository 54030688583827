@mixin flex-widths-small($bp-name:null){
    $breakpoint:null;
    @if $bp-name{
        $breakpoint: #{$inuit-widths-breakpoint-separator}#{$bp-name}
    }
    .u-flex-1#{$inuit-widths-delimiter}1#{$breakpoint} {
        flex: 0 0 100%!important;
        max-width: 100%!important
    }
    .u-flex-1#{$inuit-widths-delimiter}2#{$breakpoint} {
        flex: 0 0 50%!important;
        max-width: 50%!important
    }
    .u-flex-1#{$inuit-widths-delimiter}3#{$breakpoint} {
        flex: 0 0 33.333%!important;
        max-width: 33.333%!important
    }
    .u-flex-1#{$inuit-widths-delimiter}4#{$breakpoint} {
        flex: 0 0 25%!important;
        max-width: 25%!important
    }
    .u-flex-1#{$inuit-widths-delimiter}6#{$breakpoint} {
        flex: 0 0 16.666%!important;
        max-width: 16.666%!important
    }
    .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1#{$inuit-widths-delimiter}12#{$breakpoint} {
        margin-right: 8.333%!important
    }

    .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1#{$inuit-widths-delimiter}12#{$breakpoint} {
        margin-left: 8.333%!important
    }
    .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1#{$inuit-widths-delimiter}24#{$breakpoint} {
        margin-right: 4.166%!important
    }
    
    .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1#{$inuit-widths-delimiter}24#{$breakpoint} {
        margin-left: 4.166%!important
    }
    .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1#{$inuit-widths-delimiter}12#{$breakpoint} {
        margin-left: 8.333%!important
    }    
    .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1#{$inuit-widths-delimiter}12#{$breakpoint} {
        margin-right: 8.333%!important
    }    
    .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1#{$inuit-widths-delimiter}24#{$breakpoint} {
        margin-left: 4.166%!important
    }    
    .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1#{$inuit-widths-delimiter}24#{$breakpoint} {
        margin-right: 4.166%!important
    }
    .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1#{$inuit-widths-delimiter}12#{$breakpoint} {
        margin-left: -8.333%!important
    }    
    .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1#{$inuit-widths-delimiter}12#{$breakpoint} {
        margin-right: -8.333%!important
    }
}
@include flex-widths-small();
@each $bp-name, $bp-value in $mq-breakpoints {
    @include mq($from: $bp-name) {
        @include flex-widths-small($bp-name);
    }
}