.c-tags {
  &--list {
    font-family: $global-heading-font-family;
    color: color-var(font, 3);
    font-weight: 500;

    font-size: $inuit-font-size-h6;

    .c-tags {
      &__item {
        display: flex;
        border-bottom: 1px solid #d2d2d2;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;

        span {
          font-weight: 400;
          color: color-var(font, 2);
        }

        &:last-child {
          border: 0;
        }
      }
    }
  }

  &--block {

    .c-tags {
      &__item {
        padding: 8px 20px;
        min-width: 70px;
        color: #3D3D3D;
        font-family: $global-heading-font-family;
        font-size: $inuit-font-size-h8;
        line-height: $inuit-font-size-h7;
        text-align: center;
        border: 1px solid #D2D2D2;
        border-radius: 4px;
      }
    }
  }
}
