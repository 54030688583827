/* ==========================================================================
   #TEXTAREA
   ========================================================================== */

.c-textarea {
  @include formInput();
  // border-top: 0;
  // border-left: 0;
  // border-right: 0;
  padding: $inuit-global-spacing-unit;
  min-height: 135px;
  margin: 0 0 $inuit-global-spacing-unit-small;
  @include transition();
}
