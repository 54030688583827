/*
    ==========================================================================||
    By default, items in will be left aligned, wrappable and not growable
    ==========================================================================||
*/

.o-flex {
    display: flex;
}

.o-flex--auto,
.o-flex--grids {
    flex-wrap: wrap;
    margin-left: -$inuit-global-spacing-unit*2;
    >.o-flex__item {
        padding-left: $inuit-global-spacing-unit*2;
    }
}

.o-flex--auto {
    >.o-flex__item {
        flex: 1;
    }
}

.o-flex--grids {
    align-items: flex-start;
    flex-flow: row wrap;
    >.o-flex__item {
        flex: 0 0 100%;
        width: 100%;
    }
}

/*
    ==========================================================================||
    Ensures that height of all .o-flex__item to match one another
    ==========================================================================||
  */

.o-flex--grids-stretched {
    align-items: stretch;
}

/*
    ==========================================================================||
    Modifiers for alignment of items
    --centered: center all items vertically and horizontally
    --centered-v: center all items vertically only
    --centered-h: center all items horizontally only
    ==========================================================================||
*/

.o-flex--centered {
    align-items: center;
    justify-content: center;
}

.o-flex--centered-v {
    align-items: center;
}

.o-flex--centered-h {
    justify-content: center;
}

/*
    ==========================================================================||
    Modifiers for layout (direction) of items
    --reversed: flex-direction = row-reverse
    --column: flex-direction = column
    --column-reversed: flex-direction = column-reverse 
    ==========================================================================||
*/

.o-flex--reversed {
    flex-direction: row-reverse;
}

/*
    ==========================================================================||
    Note: flex-direction column/column-reverse will take full width of parent
    container and is not affected by flex-basis to set width
    
    Reference: https://codepen.io/townivan/post/flexbox-flex-direction-column
    ==========================================================================||
*/

.o-flex--column {
    flex-direction: column;
}

.o-flex--column-reversed {
    flex-direction: column-reverse;
}

/*
    ==========================================================================||
    Modifiers for wrapping of items
    --wrap: flex-wrap = wrap == using .o-flex--grids
    --wrap-reverse: flex-wrap = wrap-reverse
    ==========================================================================||
*/

.o-flex--nowrap {
    flex-wrap: nowrap;
}

.o-flex--wrap {
    flex-wrap: wrap;
}

.o-flex--wrap-reverse {
    flex-wrap: wrap-reverse;
}

/*
    ==========================================================================||
    Modifiers for adding spacing between elements
    --space-between: adds spacing between items only
    --space-around: adds spacing on both side of all items
    ==========================================================================||
*/

.o-flex--space-between {
    justify-content: space-between;
}

.o-flex--space-around {
    justify-content: space-around;
}

/*
    ==========================================================================||
    Modifiers for locking elements in respect to container
    --lock-left: lock elements to left side
    --lock-right: lock elements to right side
    --lock-top: lock elements to top side
    --lock-bottom: lock elements to bottom side
    --lock-top-left: lock elements to top left side
    --lock-top-right: lock elements to top right side
    --lock-bottom-left: lock elements to bottom left side
    --lock-bottom-right: lock elements to bottom right side
    ==========================================================================||
*/

.o-flex--lock-left {
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}

.o-flex--lock-right {
    align-content: center;
    align-items: center;
    justify-content: flex-end;
}

.o-flex--lock-top {
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
}

.o-flex--lock-bottom {
    align-content: flex-end;
    align-items: flex-end;
    justify-content: center;
}

.o-flex--lock-top-left {
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
}

.o-flex--lock-top-right {
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-end;
}

.o-flex--lock-bottom-left {
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-start;
}

.o-flex--lock-bottom-right {
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
}