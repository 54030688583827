/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

.o-link {
  text-decoration: underline;

  &--primary {
    color: inherit;

    &:hover {
      color: color-var(font, 4);
    }
  }
}
