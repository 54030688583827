.c-offer-form {
  padding: 4rem 0;
  background: color-var(bg, 3);
  position: relative;
  z-index: 2;

  &__form {
    margin-top: 3.33rem;
    margin-bottom: 4em;
  }

  &__desc {
    color: rgba(color-var(font), 0.5);

    .o-link {
      color: color-var(font);
    }
  }

  &__option {
    border: 1px solid rgba(color-var(border, 5), 0.15);
    padding: 0.9rem;
    margin-bottom: 1.6rem;

    @include mq($until: medium) {
      margin-bottom: 0.6rem;

    }

    &s {
      margin-bottom: 1.1rem;
    }
  }
}
