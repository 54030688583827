/* ==========================================================================
   #Text
   ========================================================================== */

.c-text {
  padding: 4.5em 0;
  position: relative;
  // z-index: 1;

  &__title {
    margin-bottom: 3rem;
  }

  &__desc {
    line-height: 2em;
  }

  &__button {
    margin-top: 2rem;
  }

  &__content {

    @include mq($until:medium) {
      br {
        display: none;
      }
    }
  }

  &__gallery {
    text-align: right;
    max-height: 100px;
    position: relative;
    z-index: 1;

    img {
      margin-bottom: 30px;
    }
  }

  &__sidebar {
    &--gallery {
      padding-left: 5rem !important;
    }

    &--picture {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5rem !important;
    }

    &--slider {
      position: relative;
      align-self: center;
    }

    &--team {
      margin-top: 4rem;
    }
  }


  &__anim {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
    width: 1920px;
    height: auto;
    // z-index: -1;
    pointer-events: none !important;
  }

  &__timeline {
    display: flex;
    height: 290px;
    position: relative;
    counter-reset: timeline;

    @include mq($until:medium) {
      flex-wrap: wrap;
      height: auto;
    }

    &:before {
      content: "";
      width: 100vw;
      height: 2px;
      background: #d2d2d2;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -50vw;
      margin-top: -1px;

      @include mq($until:medium) {
        display: none;
      }

    }

    &-text {
      flex: 0 0 100%;
      height: 120px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 10px;

      @include mq($until:medium) {
        height: auto;
        margin: 30px 0;
      }
    }

    &-item {
      counter-increment: timeline;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex: 1;
      padding: 0 10px;
      @include inuit-font-size($inuit-font-size-h6);
      align-self: flex-start;

      @include mq($until:medium) {
        flex: 0 0 100%;
      }

      &:before {
        content: counter(timeline);
        display: flex;
        justify-content: center;
        align-items: center;
        @include square-style(50px);
        border-radius: 25px;
        color: color-var(font);
        background-color: color-var(bg, 5);
        line-height: 50px;
        font-size: 1rem;
        font-family: $global-heading-font-family;
        font-weight: 500;
        margin: auto;
        order: 1;
        position: relative;
        z-index: 1;

        @include mq($until:medium) {
          order: -1;
        }
      }

      &:nth-child(2n) {
        align-self: flex-end;

        .c-text__timeline-text {
          order: 2;
          align-items: flex-start;
          padding-bottom: 0;
          padding-top: 10px;
        }
      }
    }
  }

  &__slider {

    .c-slider {
      &__slide {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        min-width: 33.33%;
        text-align: left;
      }
    }
  }
}
