.c-color-box {
  min-height: 100px;
  margin: $inuit-global-spacing-unit-big 0;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-styleguide-section {
  margin-top: $inuit-global-spacing-unit-big;
  padding: 30px 15px;

  .c-button {
    margin-bottom: 15px;
  }

  .c-heading {
    margin-bottom: 15px;
  }

  &.c-expert-section {
    background: color-var(bg, 2);
  }
}

.page-template-stylegiude {
  background: lighten(color-var(bg), 10%);
  color: color-var(font, 2);
}
