/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;

  @include mq($until: xlarge) {
    padding: 0 30px;
  }

  @include mq($until: medium) {
    padding: 0 15px;
  }
}
