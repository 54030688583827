/* ==========================================================================
   #Icons
   ========================================================================== */

.c-icon {
  &-list {
    padding-top: 20px;
  }

  &s {
    margin-top: 60px;
    position: relative;
    align-items: center;
    @include inuit-font-size($inuit-font-size-h6);

    &:before {
      position: absolute;
      top: 0;
      left: 20%;
      content: "";
      display: block;
      width: 1px;
      background: #d8d8d8;
      height: 100%;

      @include mq($until:medium) {
        display: none;
      }
    }

    img {
      max-height: 80px;
      margin: 25px 0;
    }
  }
}
