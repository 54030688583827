@mixin responsiveClasses($classname){
    .#{$classname} {
        @content;
    }
    @if (variable-exists(mq-breakpoints)) {
        @each $bp-name-start,$bp-value-start in $mq-breakpoints {
            @include mq($from: $bp-name-start) {
                .#{$classname}#{$inuit-widths-breakpoint-separator}#{$bp-name-start} {
                    @content;
                }
            }
            @include mq($until: $bp-name-start) {
                .#{$classname}#{$inuit-widths-breakpoint-separator}less-then-#{$bp-name-start} {
                    @content;
                }
            }
            @each $bp-name-end,$bp-value-end in $mq-breakpoints {
                @if ($bp-value-start < $bp-value-end) {
                    @include mq($from: $bp-name-start, $until: $bp-name-end) {
                        .#{$classname}#{$inuit-widths-breakpoint-separator}#{$bp-name-start}-till-#{$bp-name-end} {
                            @content;
                        }
                    }
                }
            }
        }
    }
} 