@mixin EmbedAFont( $path, $weight: normal, $style: normal ){
	@font-face {
		$f: "#{$path}.";
		$name: AFont;
		font-family: 'AFont';
		src: url("#{$f}eot");
		src: url("#{$f}eot?#iefix") format('embedded-opentype'),
			 url("#{$f}woff") format('woff'),
			 url("#{$f}ttf") format('truetype'),
			 url("#{$f}svg#{$name}") format('svg');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

@include EmbedAFont( "../assets/fonts/AFont/AFont-400", 400, normal );
@include EmbedAFont( "../assets/fonts/AFont/AFont-500", 500, normal );


@mixin AFont( $weight: normal, $style: normal ) {
	font-family: "AFont";
	font-weight: $weight;
	font-style: $style;
}
@mixin EmbedObjectSans( $path, $weight: normal, $style: normal ){
	@font-face {
		$f: "#{$path}.";
		$name: ObjectSans;
		font-family: 'ObjectSans';
		src: url("#{$f}eot");
		src: url("#{$f}eot?#iefix") format('embedded-opentype'),
			 url("#{$f}woff") format('woff'),
			 url("#{$f}ttf") format('truetype'),
			 url("#{$f}svg#{$name}") format('svg');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

@include EmbedObjectSans( "../assets/fonts/ObjectSans/ObjectSans-400", 400, normal );
@include EmbedObjectSans( "../assets/fonts/ObjectSans/ObjectSans-700", 700, normal );


@mixin ObjectSans( $weight: normal, $style: normal ) {
	font-family: "ObjectSans";
	font-weight: $weight;
	font-style: $style;
}
