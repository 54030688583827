@mixin square-style($size: 30px) {
    width: $size;
    height: $size;
} 
@mixin transition($elem: all, $type: linear, $time: 0.2s) {
    transition: $elem $type $time;
}  
@mixin absolutePseudoContent(){
    content: "";
    display: block;
    position: absolute;
}
@mixin formInput(){
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    font: inherit;
    outline: none;
    border: 2px solid color-var(border, 5);
    color: color-var(font,2);  
    background: color-var(bg,2);
    transition: all 0.3s linear; 
    font-size: $inuit-font-size-h6;
    border-radius: 0;
    box-shadow: none !important;-webkit-appearance: none;
    &--disabled {
      border-style: dashed;
      pointer-events: none;
      color: color-var(font, 2);
      &:hover {
        border-color: color-var(border, 5)
      }
    }

    &::placeholder {
        color: rgba(color-var(font,2),0.5);
    }
  
    &:focus,
    &:active {
      border-color: color-var(border);
    }
  }
@mixin clickableInput(){
    $self: &;
    cursor: pointer;
    position: relative;
    padding-left: 53px;
    line-height: 34px;
    margin-bottom: $inuit-global-spacing-unit;
    &.c-label--inline{
        padding-left: 58px;
    }
    &__replacement {
      display: inline-block;
      @include square-style(34px);
      border: 1px solid color-var(border);
      vertical-align: middle;
      margin-right: $inuit-global-spacing-unit-small;
      @include transition();
      // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.10);
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
      &:before{
        content: "";
        display: block;
        position: absolute;
      }
    }
    &.u-disabled {
      #{$self}__replacement  {
        box-shadow: none;
        border-color: color-var(border, 5)!important;
      }
    }
    &:hover {
      #{$self}__replacement {
        border-color: color-var(border, 5);
      } 
    }
    input:checked {
      &+#{$self}__replacement {
        background: color-var(bg,2);
        &:before,
        &:after {
          border: 1px solid color-var(border,2);
          background: color-var(bg);
        }
      }
    }
  }
  
  @mixin checkboxPart($left:4px,$bottom:16px,$width:14px,$degree:45deg,$origin:bottom left){
      left: $left;
      bottom: $bottom;
      width: $width;
      transform: rotate($degree);
      transform-origin: $origin;
  }
  @mixin tick($colored: false){    
    &:before,
    &:after {
      @include absolutePseudoContent();
      height: 4px;
    }
    @if($colored){
        &:before,
        &:after {
        background: color-var(border, 2);
        }
    }
  }
  @mixin checkboxTick($colored: false){
      @include tick($colored);
      &:before {
        @include checkboxPart();
      }
      &:after {
        @include checkboxPart(12px, 5px, 20px, -50deg, top left);
      }
  }
  @mixin arrowTick($colored: false){    
      @include tick($colored);
      &:before {
        @include checkboxPart(calc(100% - 20px), 24px, 10px, 45deg, top left);
      }
      &:after {
        @include checkboxPart(calc(100% - 14px), 17px, 10px, -45deg, top left);
      }
  }