.c-sidebar {
  padding-top: 2em;

  @include mq($from: large) {
    padding-left: 70px;
  }

  &__heading {
    margin-bottom: 35px;
  }

  &__item {
    margin-bottom: 100px;
  }
}
