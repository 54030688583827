.c-tease {
  background: color-var(bg, 2);
  color: color-var(font, 2);
  padding: 1.5rem 2rem;
  border: 1px solid #d2d2d2;
  margin-top: 1rem;

  &--full {
    padding: 30px;
    height: 100%;
  }

  &__desc {
    padding-bottom: 50px;
    line-height: 25px;
    font-size: $inuit-font-size-h7;
    margin: 0;
    position: relative;
  }

  &__image {
    margin-bottom: 30px;
  }
}
