.c-testimonials-slider {
    color: color-var(font, 1);
    padding: 1rem 0;
    background: color-var(bg, 3);
    position: relative;
    z-index: 2;

    &__wrap {
        margin-top: 3rem;
        position: relative;
        margin-bottom: 5rem;
    }

    &__pager {
        bottom: -1rem !important;
        margin-bottom: 0;
        transform: translateY(100%);
        right: 0 !important;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;


        .c-slider__page {
            margin-left: 30px;

            @include mq($until: large) {
                margin-left: 20px;
            }
        }
    }
    &__price {
        @include inuit-font-size($inuit-font-size-h7);
    
        @include mq($until: medium) {
          &-desc.o-flex {
            flex-wrap: wrap;
          }
        }
    
        img {
          margin-bottom: 15px;
        }
    
        @include mq($until: medium) {
          .c-about & {
            text-align: center;
          }
        }
      }
}