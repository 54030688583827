/* ==========================================================================
   #person
   ========================================================================== */

.c-person {
  position: relative;
  margin-bottom: 2rem;

  &__anim {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__subscription {
    background: color-var(bg, 2);
    padding: 63px 30px 34px;
  }

  &__pic {
    text-align: center;
    margin-bottom: -30px;

    img {
      height: 400px;
    }
  }

  &__title {
    font-family: $global-heading-font-family;
    font-weight: 500;
  }

  &__desc {
    @include inuit-font-size($inuit-font-size-h7);
    color: #616161;
    line-height: 1.5em
  }
}
