/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  margin: 0;

  @include mq($until: medium) {
    display: block;
  }
}

.c-main-nav__item {
  @include inuit-font-size($inuit-font-size-h6);
  list-style: none;
  margin: 0;
  margin-left: 3em;
  padding: 0;

  &:hover .c-main-nav__dropdown {
    display: flex;
  }

  @include mq($until: medium) {
    flex: 0 0 100%;
    text-align: center;
    margin-left: 0 !important;
    padding: 10px 15px;
    font-size: 16px;
  }
}

.c-main-nav__link {
  display: block;
  text-decoration: none;
  color: color-var(font);
  position: relative;
  @include line(0%, -3px, 50%);

  @include mq($until: medium) {
    color: color-var(font, 2);
  }
}

.c-main-nav__link:hover,
.current-menu-item .c-main-nav__link {
  color: color-var(font);
  @include line(100%, -3px, 0%);

  @include mq($until: medium) {
    color: color-var(font, 2);
  }
}

// .c-main-nav__dropdown {
//   display: none;
//   flex-direction: column;
//   margin: 0;
//   position: absolute;
// }

// .c-main-nav__subitem {
//   list-style: none;
//   margin: 0;
// }

// .c-main-nav__sublink {
//   background: $color-white;
//   border-bottom: 1px solid $color-border;
//   display: block;
//   padding: 0.5em;
//   text-decoration: none;
// }

// .current-menu-item .c-main-nav__sublink {
//   border-bottom-color: $color-black;
// }
