.c-scores-slider {
    color: color-var(font, 1);
    padding: 5rem 0;
    background: color-var(bg, 3);
    position: relative;
    z-index: 2;

    &__wrap {
        margin-top: 3rem;
    }

    &__pager {
        bottom: 25px !important;
        right: 0 !important;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .c-slider__page {
            margin-left: 30px;

            @include mq($until: large) {
                margin-left: 20px;
            }
        }
    }

    &__anim {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -960px;
        width: 1920px;
        height: auto;
        // z-index: -1;
        pointer-events: none !important;
      }
}