/* ==========================================================================
   #Icons
   ========================================================================== */

.c-images {
  padding: 60px 0;
  position: relative;
  z-index: 1;

  &__bg {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
    z-index: -1;
  }

  .o-wrapper>* {
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  .c-embed--video {
    padding-top: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
