/* ==========================================================================
   #Quote
   ========================================================================== */

   .c-quote {
    background: url(../assets/images/quote.svg) no-repeat top left;
  
    @include mq($from: medium) {
      background: url(../assets/images/quote.svg) no-repeat top left 70px;
    }
  
    &--alternative {
      background-position: top center;
    }
  
    &--alternative &__image {
      width: 60px;
      height: 60px;
    }
  
    &--alternative &__author {
      font-size: .8em;
      color: rgba(#fff, .6);
    }
  
    padding-top: 60px;
  
    @include mq($from: medium) {
      padding-left: 70px;
    }
  
    color: color-var(font);
  
    &__desc {
      line-height: 2em;
    }
  
    &__subscribe {
      display: flex;
      justify-content: center;
      align-items: center;
      @include inuit-font-size($inuit-font-size-h6);
      margin-top: 2em;
    }
  
    &__author {
      margin-left: 1em;
    }
  
    &__image {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
  }
  