/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

 .c-button {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.88rem 2.9rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: $global-transition;
  @include inuit-font-size($inuit-font-size-h7);
  text-transform: uppercase;
  font-family: $global-heading-font-family;
  font-weight: 500;
  line-height: 2.5em;
  text-decoration: none;
  border: 0;
  border-radius: 0;
  transition: background-color 0.3s linear;

  @include mq($until:medium) {
    &:not(.c-button--tertiary) {
      width: 100%;
      @include inuit-font-size($inuit-font-size-h5 - 2px);
      padding: 1.5em;
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &__triangle {

    * {
      fill: color-var(bg);
    }
  }

  &__ico {
    font-size: 1.5em;
    font-weight: 400;
    margin-right: 10px;
  }

  /* Style variants
   ========================================================================== */

  &--primary {
    background-color: color-var(bg, 4);
    color: color-var(font, 2);

    &:hover,
    &:active {
      background-color: color-var(bg, 2);
      color: color-var(font, 2);
    }
  }

  &--secondary {
    background-color: color-var(bg);
    color: color-var(font);

    &:hover,
    &:active {
      color: color-var(font, 2);
      background-color: color-var(bg, 4);
    }
  }

  &--tertiary {
    text-transform: none;
    color: color-var(font, 4);
    font-family: $global-heading-font-family;
    font-weight: 500;
    padding: 0;
    line-height: 1.33rem;
    margin: 1em 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include inuit-font-size($inuit-font-size-h6);

    &:hover,
    &:active {
      color: color-var(font);
    }
  }

  &--outline {
    border: 1px solid color-var(bg);
    background-color: transparent;
    color: color-var(font, 3);

    &:hover,
    &:active {
      background-color: color-var(bg);
      color: color-var(font);
    }
  }


  &--alternative {
    padding: 0;
    padding-right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include inuit-font-size($inuit-font-size-h8);
    line-height: 3em;
    color: color-var(font, 3);
    letter-spacing: 1px;
    @include mq($until:medium) {
     span{
       max-width:calc( 100% - 40px )
     }
    }
    svg {
      position: relative;
      left: 0;
      transition: all 0.3s linear;
    }

    &:hover,
    &:active,
    &:focus {
      svg {
        left: 5px;
      }
    }
  }

  &--read-more {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  /* Size variants
   ========================================================================== */

  &--small {
    padding: 0.25rem 0.5rem;
  }

  &--large {
    padding: 0.75rem 1.5rem;
  }

}
