/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.c-checkbox {
  @include clickableInput();

  &__replacement {
    border-radius: 0;
    @include checkboxTick();
  }

  @include inuit-font-size($inuit-font-size-h6 - 2px);
}