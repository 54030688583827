.c-logo {
    &s {
        color: color-var(font, 2);
        padding: 3rem 0;
        background: color-var(bg, 7);
        position: relative;
        z-index: 2;

        &__desc {
            font-family: $font-sans-serif;
            font-weight: 500;
            font-size: 16px;
        }
    }
    height: 100px;
    max-height: 100px;
    line-height: 100px;

    img {
        max-height: 100px;
        width: auto;
        max-width: 90%;
        height: auto;
    }
}