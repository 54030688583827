/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  padding: 2em 0 0;
  position: relative;

  &:after {
    content: "";
    display: block;
    background: #d2d2d2;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -110px;
  }

  &__content {
    padding: 50px 0 0;

    @include mq($from: medium) {
      padding: 50px 50px 0;
    }

    line-height: 2rem;

    &>* {
      margin-bottom: 40px;
    }
  }
}

.editor-block-list__layout,
.s-wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(.c-heading) {
      font-family: $global-body-font-family;
      font-weight: 400;
      @include inuit-font-size($inuit-font-size-h4, $line-height: "none", $important: true);
      margin-bottom: 15px;
    }
  }

  ul,
  ol,
  dd {
    margin-left: 1.5rem;

    li,
    dl {
      padding-left: 0.5rem
    }
  }

  .wp-block-embed {
    @include mq($from: medium) {
      margin-left: -50px;
      margin-right: -50px;
    }

    .c-embed--video {
      padding-top: 56.25%;
      position: relative;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  a{
    color: #001fa3;
  }
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

@include mq($until: medium) {
  .ssba {
    &>div{
      padding:5px 5px!important;
    .ssbp-list li {
      margin-left: 0 !important;
    }}
  }
}
