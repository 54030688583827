/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post-archive-header {
  background: color-var(bg, 2);
  color: color-var(font, 2);
  padding-bottom: 50px;

  .c-post__desc {
    position: relative;
    line-height: 2rem;
    font-size: 1rem;
    padding-bottom: 60px;

    .c-button {
      bottom: 10px;
    }
  }

}

@include mq($until: medium) {
  .c-post-archive-header {
    padding:0!important;
  }
}
