.c-team-member {
    margin-bottom: 1em;

    &__avatar {
        border-radius: 50%;
        margin-bottom: .75em;
    }

    &__name {
        font-weight: 500;
        font-size: .9em;
        font-family: $global-heading-font-family;
    }

    &__desc {
        font-size: .75em;
        line-height: 1.5;

        a {
            text-decoration: underline;
        }
    }
}