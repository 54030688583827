.c-accordion {
    &s {
        color: color-var(font, 2);
        padding: 5rem 0;
        background: color-var(bg, 7);
        position: relative;
        z-index: 2;
    
        &__desc {
          line-height: 2em;
        }

        &__wrapper {
            max-width: 1024px;
        }

        &__link-before {
            font-weight: 500;
            font-family: $font-sans-serif;
        }
    }

    border: 1px solid #ccc;
    background: #fff;
    padding: 1.5em;
    margin-bottom: 2em;

    &__handle {
        border: 0;
        cursor: hand;
        cursor: pointer;
        background: none;
        width: 100%;
        text-align: left;
        position: relative;
        outline: none;
        color: color-var(font, 3);
        font-weight: 500;
        padding: 0;
        font-size: 1em;
        line-height: 1.4;
        font-family: $font-sans-serif;
    }

    &__icon {
        width: 1em;
        height: 1em;
        position: relative;
        float: right;
        margin-left: .25em;
    }

    &__icon-bar {
        display: block;
        width: .75em;
        height: 2px;
        background: color-var(bg, 1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform .3s, opacity .3s;
    }

    &__icon-bar:nth-child(1) {
        opacity: 1;
        transform: translate(-50%, -50%) rotate(90deg);
    }

    &.is-open &__icon-bar:nth-child(1) {
        opacity: 0;
        transform: translate(-50%, -50%) rotate(0deg);
    }

    &__content {
        display: none;
        line-height: 2em;
        margin-top: 1em;
        font-size: .9em;
    }

    &__content p {
        margin-bottom: 0;
    }
}