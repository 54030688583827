/* ==========================================================================
   #RADIO BUTTON
   ========================================================================== */

.c-radio {
  @include clickableInput();

  &__replacement {
    border-radius: 17px;

    &:before {
      @include absolutePseudoContent();
      @include square-style(17px);
      border-radius: 9px;
      left: 7px;
      top: 7px;
    }
  }
}
