/* ==========================================================================
   #INPUT
   ========================================================================== */

.c-input {
  @include formInput();
  // border-bottom: 0;
  // border-left: 0;
  // border-right: 0;
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
  line-height: 1.3em;
  margin-bottom: 20px;
  @include transition();

}
