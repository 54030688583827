@charset "UTF-8";
/*
  Project: Kerris Select
  Author: Michał Strześniewski
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/*
    ========================================================================
    #COLORS
    ========================================================================
   
    A map of global config settings. Define any project-level configuration,
    feature switches, etc. in here.
*/
/* 
    ========================================================================
    #INUIT VARIABLES
    ======================================================================== 

    The inuit variables file contains any variables which has to overwrite 
    default variables.
*/
/*
    This core file sets up inuitcss’ most important setup variables. They
    underpin a lot of how the framework functions and should be modified and
    preconfigured with caution.

    Baseline grid lines height.
    Every spacing metric should be based on this.
*/
/*
    How many grid lines should our spacing unit variants span?
    Each value should be an unitless integer.
*/
/*
    Base typographical styles.
*/
/*
    Base headings.
*/
/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 *   .u-padding-horizontal {}
 *   .u-padding-vertical-small {}
 */
.u-flex-1\/1 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.u-flex-1\/2 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.u-flex-1\/3 {
  flex: 0 0 33.333% !important;
  max-width: 33.333% !important;
}

.u-flex-1\/4 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.u-flex-1\/6 {
  flex: 0 0 16.666% !important;
  max-width: 16.666% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/12 {
  margin-right: 8.333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/12 {
  margin-left: 8.333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/24 {
  margin-right: 4.166% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/24 {
  margin-left: 4.166% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12 {
  margin-left: 8.333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12 {
  margin-right: 8.333% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24 {
  margin-left: 4.166% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24 {
  margin-right: 4.166% !important;
}

.o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12 {
  margin-left: -8.333% !important;
}

.o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12 {
  margin-right: -8.333% !important;
}

@media (min-width: 30em) {
  .u-flex-1\/1\@small {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/2\@small {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-1\/3\@small {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-1\/4\@small {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-1\/6\@small {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/12\@small {
    margin-right: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/12\@small {
    margin-left: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/24\@small {
    margin-right: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/24\@small {
    margin-left: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@small {
    margin-left: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@small {
    margin-right: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@small {
    margin-left: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@small {
    margin-right: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@small {
    margin-left: -8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@small {
    margin-right: -8.333% !important;
  }
}

@media (min-width: 48em) {
  .u-flex-1\/1\@medium {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/2\@medium {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-1\/3\@medium {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-1\/4\@medium {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-1\/6\@medium {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/12\@medium {
    margin-right: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/12\@medium {
    margin-left: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/24\@medium {
    margin-right: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/24\@medium {
    margin-left: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@medium {
    margin-left: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@medium {
    margin-right: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@medium {
    margin-left: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@medium {
    margin-right: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@medium {
    margin-left: -8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@medium {
    margin-right: -8.333% !important;
  }
}

@media (min-width: 64em) {
  .u-flex-1\/1\@large {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/2\@large {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-1\/3\@large {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-1\/4\@large {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-1\/6\@large {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/12\@large {
    margin-right: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/12\@large {
    margin-left: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/24\@large {
    margin-right: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/24\@large {
    margin-left: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@large {
    margin-left: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@large {
    margin-right: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@large {
    margin-left: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@large {
    margin-right: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@large {
    margin-left: -8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@large {
    margin-right: -8.333% !important;
  }
}

@media (min-width: 80em) {
  .u-flex-1\/1\@xlarge {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/2\@xlarge {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-1\/3\@xlarge {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-1\/4\@xlarge {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-1\/6\@xlarge {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/12\@xlarge {
    margin-right: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/12\@xlarge {
    margin-left: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/24\@xlarge {
    margin-right: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/24\@xlarge {
    margin-left: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@xlarge {
    margin-left: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@xlarge {
    margin-right: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@xlarge {
    margin-left: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@xlarge {
    margin-right: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@xlarge {
    margin-left: -8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@xlarge {
    margin-right: -8.333% !important;
  }
}

@media (min-width: 85.375em) {
  .u-flex-1\/1\@xxlarge {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/2\@xxlarge {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-1\/3\@xxlarge {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-1\/4\@xxlarge {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-1\/6\@xxlarge {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/12\@xxlarge {
    margin-right: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/12\@xxlarge {
    margin-left: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/24\@xxlarge {
    margin-right: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/24\@xxlarge {
    margin-left: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@xxlarge {
    margin-left: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@xxlarge {
    margin-right: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@xxlarge {
    margin-left: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@xxlarge {
    margin-right: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@xxlarge {
    margin-left: -8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@xxlarge {
    margin-right: -8.333% !important;
  }
}

@media (min-width: 100em) {
  .u-flex-1\/1\@full {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/2\@full {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-1\/3\@full {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-1\/4\@full {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-1\/6\@full {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/12\@full {
    margin-right: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/12\@full {
    margin-left: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/24\@full {
    margin-right: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/24\@full {
    margin-left: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@full {
    margin-left: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@full {
    margin-right: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@full {
    margin-left: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@full {
    margin-right: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@full {
    margin-left: -8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@full {
    margin-right: -8.333% !important;
  }
}

@media (min-width: 120em) {
  .u-flex-1\/1\@xfull {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/2\@xfull {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-1\/3\@xfull {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-1\/4\@xfull {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-1\/6\@xfull {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/12\@xfull {
    margin-right: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/12\@xfull {
    margin-left: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-inset-1\/24\@xfull {
    margin-right: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-inset-1\/24\@xfull {
    margin-left: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/12\@xfull {
    margin-left: 8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/12\@xfull {
    margin-right: 8.333% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-offset-1\/24\@xfull {
    margin-left: 4.166% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-offset-1\/24\@xfull {
    margin-right: 4.166% !important;
  }
  .o-flex:not(.o-flex--reversed) .o-flex__item.u-flex-pull-1\/12\@xfull {
    margin-left: -8.333% !important;
  }
  .o-flex.o-flex--reversed .o-flex__item.u-flex-pull-1\/12\@xfull {
    margin-right: -8.333% !important;
  }
}

/*
    ==========================================================================||
    Overwrites inuitcss/utilities/widths as it will be used alongside o-flex
    instead of o-layout
    ==========================================================================||
    Inherited from inuitcss
*/
/*
    ==========================================================================||
    Mixin to spit out width classes similar to inuitcss except that
    u-push/u-pull is replaced with u-offset which its direction is influenced
    by o-flex's direction and if $inuit-offsets is set to true

    e.g.
    <div class="u-flex-1/2"></div>
    <div class="u-flex-1/2 u-flex-offset-1/2"></div>
    ==========================================================================||
*/
/* 
    ==========================================================================||
    Various mixins to make development easier should there be different
    behaviour on different breakpoints.
    ==========================================================================||
    ==========================================================================||
    Mixin: inuit-flex-direction($direction)
    Defines flex-direction property for flex container
    ==========================================================================||
    Parameter(s)
    $direction [string] (default: "row") - accepted values:
    - row
    - row-reverse
    - column
    - column-reverse
    ==========================================================================||
*/
/*
    ==========================================================================||
    Mixin: inuit-flex-position($position)
    Defines position of child elements within container
    ==========================================================================||
    Parameter(s)
    $position [string] (default: "lock-top-left") - accepted values:
    - centered
    - centered-v
    - centered-h
    - lock-left
    - lock-right
    - lock-top
    - lock-bottom
    - lock-top-left
    - lock-top-right
    - lock-bottom-left
    - lock-bottom-right
    ==========================================================================||
*/
/*
    ==========================================================================||
    Mixin: inuit-flex-wrap($wrap)
    Defines flex-wrap property for flex container
    ==========================================================================||
    Parameter(s)
    $wrap [string] (default: "wrap") - accepted values:
    - wrap
    - wrap-reverse
    - nowrap
    ==========================================================================||
*/
/*
    ==========================================================================||
    Mixin: inuit-flex-spacing($spacing)
    Defines justify-content property for flex container
    ==========================================================================||
    Parameter(s)
    $spacing [string] (default: "") - accepted values:
    - space-between
    - space-around
    ==========================================================================||
*/
@font-face {
  font-family: 'AFont';
  src: url("../assets/fonts/AFont/AFont-400.eot");
  src: url("../assets/fonts/AFont/AFont-400.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AFont/AFont-400.woff") format("woff"), url("../assets/fonts/AFont/AFont-400.ttf") format("truetype"), url("../assets/fonts/AFont/AFont-400.svgAFont") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AFont';
  src: url("../assets/fonts/AFont/AFont-500.eot");
  src: url("../assets/fonts/AFont/AFont-500.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AFont/AFont-500.woff") format("woff"), url("../assets/fonts/AFont/AFont-500.ttf") format("truetype"), url("../assets/fonts/AFont/AFont-500.svgAFont") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ObjectSans';
  src: url("../assets/fonts/ObjectSans/ObjectSans-400.eot");
  src: url("../assets/fonts/ObjectSans/ObjectSans-400.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/ObjectSans/ObjectSans-400.woff") format("woff"), url("../assets/fonts/ObjectSans/ObjectSans-400.ttf") format("truetype"), url("../assets/fonts/ObjectSans/ObjectSans-400.svgObjectSans") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ObjectSans';
  src: url("../assets/fonts/ObjectSans/ObjectSans-700.eot");
  src: url("../assets/fonts/ObjectSans/ObjectSans-700.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/ObjectSans/ObjectSans-700.woff") format("woff"), url("../assets/fonts/ObjectSans/ObjectSans-700.ttf") format("truetype"), url("../assets/fonts/ObjectSans/ObjectSans-700.svgObjectSans") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
.wp-block-quote {
  font-style: italic;
  background: url(../assets/images/quote-blue.svg) no-repeat top 5px left;
  padding-left: 70px !important;
  border: 0;
}

.wp-block-pullquote {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-style: solid;
  border-color: #d2d2d2;
  padding: 20px 30px !important;
  font-size: 0.89rem !important;
  line-height: 2rem;
  text-align: left !important;
  color: #565656;
}

.wp-block-pullquote * {
  margin: 0 !important;
  font-size: 0.89rem !important;
  line-height: 2rem !important;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #001FA3;
  color: #fff;
  font-family: "ObjectSans", Arial;
  font-size: 18px;
  font-weight: 400;
  /* [1] */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: relative;
}

@media (max-width: 47.99em) {
  html {
    font-size: 0.875em;
    /* 14px */
  }
}

body {
  overflow-x: hidden;
  position: relative;
  padding-top: 125px;
  transition: padding linear 0.2s;
}

body.single-post > main {
  background: #fff;
  color: #171717;
}

@media (max-width: 47.99em) {
  body.single-post > main {
    margin-top: -55px;
  }
}

@media (min-width: 48em) {
  body.single-post > main {
    text-align: justify;
  }
}

body.is-scrolled {
  padding-top: 200px;
}

.editor-block-list__layout {
  background: #001FA3;
  color: #fff;
  font-family: "ObjectSans", Arial;
  font-size: 18px;
  /* [1] */
  line-height: 1.5;
  overflow-x: hidden;
}

.post-type-post .editor-block-list__layout {
  background: #fff;
  color: #171717;
}

@media (min-width: 48em) {
  .post-type-post .editor-block-list__layout {
    text-align: justify;
  }
}

.block-editor-block-list__layout .block-editor-default-block-appender > .block-editor-default-block-appender__content,
.block-editor-block-list__layout > .block-editor-block-list__block > .block-editor-block-list__block-edit,
.block-editor-block-list__layout > .block-editor-block-list__layout > .block-editor-block-list__block > .block-editor-block-list__block-edit {
  margin-top: 0;
  margin-bottom: 0;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
}

.o-link {
  text-decoration: underline;
}

.o-link--primary {
  color: inherit;
}

.o-link--primary:hover {
  color: #6DEED8;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/*
    ==========================================================================||
    By default, items in will be left aligned, wrappable and not growable
    ==========================================================================||
*/
.o-flex {
  display: flex;
}

.o-flex--auto,
.o-flex--grids {
  flex-wrap: wrap;
  margin-left: -30px;
}

.o-flex--auto > .o-flex__item,
.o-flex--grids > .o-flex__item {
  padding-left: 30px;
}

.o-flex--auto > .o-flex__item {
  flex: 1;
}

.o-flex--grids {
  align-items: flex-start;
  flex-flow: row wrap;
}

.o-flex--grids > .o-flex__item {
  flex: 0 0 100%;
  width: 100%;
}

/*
    ==========================================================================||
    Ensures that height of all .o-flex__item to match one another
    ==========================================================================||
  */
.o-flex--grids-stretched {
  align-items: stretch;
}

/*
    ==========================================================================||
    Modifiers for alignment of items
    --centered: center all items vertically and horizontally
    --centered-v: center all items vertically only
    --centered-h: center all items horizontally only
    ==========================================================================||
*/
.o-flex--centered {
  align-items: center;
  justify-content: center;
}

.o-flex--centered-v {
  align-items: center;
}

.o-flex--centered-h {
  justify-content: center;
}

/*
    ==========================================================================||
    Modifiers for layout (direction) of items
    --reversed: flex-direction = row-reverse
    --column: flex-direction = column
    --column-reversed: flex-direction = column-reverse 
    ==========================================================================||
*/
.o-flex--reversed {
  flex-direction: row-reverse;
}

/*
    ==========================================================================||
    Note: flex-direction column/column-reverse will take full width of parent
    container and is not affected by flex-basis to set width
    
    Reference: https://codepen.io/townivan/post/flexbox-flex-direction-column
    ==========================================================================||
*/
.o-flex--column {
  flex-direction: column;
}

.o-flex--column-reversed {
  flex-direction: column-reverse;
}

/*
    ==========================================================================||
    Modifiers for wrapping of items
    --wrap: flex-wrap = wrap == using .o-flex--grids
    --wrap-reverse: flex-wrap = wrap-reverse
    ==========================================================================||
*/
.o-flex--nowrap {
  flex-wrap: nowrap;
}

.o-flex--wrap {
  flex-wrap: wrap;
}

.o-flex--wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
    ==========================================================================||
    Modifiers for adding spacing between elements
    --space-between: adds spacing between items only
    --space-around: adds spacing on both side of all items
    ==========================================================================||
*/
.o-flex--space-between {
  justify-content: space-between;
}

.o-flex--space-around {
  justify-content: space-around;
}

/*
    ==========================================================================||
    Modifiers for locking elements in respect to container
    --lock-left: lock elements to left side
    --lock-right: lock elements to right side
    --lock-top: lock elements to top side
    --lock-bottom: lock elements to bottom side
    --lock-top-left: lock elements to top left side
    --lock-top-right: lock elements to top right side
    --lock-bottom-left: lock elements to bottom left side
    --lock-bottom-right: lock elements to bottom right side
    ==========================================================================||
*/
.o-flex--lock-left {
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.o-flex--lock-right {
  align-content: center;
  align-items: center;
  justify-content: flex-end;
}

.o-flex--lock-top {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
}

.o-flex--lock-bottom {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: center;
}

.o-flex--lock-top-left {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}

.o-flex--lock-top-right {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-end;
}

.o-flex--lock-bottom-left {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
}

.o-flex--lock-bottom-right {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@media (max-width: 79.99em) {
  .o-wrapper {
    padding: 0 30px;
  }
}

@media (max-width: 47.99em) {
  .o-wrapper {
    padding: 0 15px;
  }
}

.c-about {
  color: #fff;
  padding: 5rem 0;
  background: #171717;
  position: relative;
  z-index: 2;
}

.c-about__title {
  margin-bottom: 3rem;
  margin-bottom: 3rem;
}

.c-about__desc {
  line-height: 2em;
}

.c-about__item-desc {
  opacity: 0.7;
}

.c-about__items {
  align-content: space-between;
}

.c-about__container {
  margin: 5rem 0;
}

.c-about__triangle {
  width: 100%;
  position: relative;
  z-index: 3;
  margin-top: 50px;
}

.c-about__image {
  position: absolute;
  right: 0;
  height: calc(50% - 15px);
  width: auto;
  max-width: 100%;
}

.c-about__images {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.c-about__image--1 {
  top: 0;
}

.c-about__image--2 {
  bottom: 0;
}

.c-about__price {
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
}

@media (max-width: 47.99em) {
  .c-about__price-desc.o-flex {
    flex-wrap: wrap;
  }
}

.c-about__price img {
  margin-bottom: 15px;
}

@media (max-width: 47.99em) {
  .c-about .c-about__price {
    text-align: center;
  }
}

.c-accordion {
  border: 1px solid #ccc;
  background: #fff;
  padding: 1.5em;
  margin-bottom: 2em;
}

.c-accordions {
  color: #171717;
  padding: 5rem 0;
  background: #F7F7F7;
  position: relative;
  z-index: 2;
}

.c-accordions__desc {
  line-height: 2em;
}

.c-accordions__wrapper {
  max-width: 1024px;
}

.c-accordions__link-before {
  font-weight: 500;
  font-family: "AFont", Arial;
}

.c-accordion__handle {
  border: 0;
  cursor: hand;
  cursor: pointer;
  background: none;
  width: 100%;
  text-align: left;
  position: relative;
  outline: none;
  color: #001FA3;
  font-weight: 500;
  padding: 0;
  font-size: 1em;
  line-height: 1.4;
  font-family: "AFont", Arial;
}

.c-accordion__icon {
  width: 1em;
  height: 1em;
  position: relative;
  float: right;
  margin-left: .25em;
}

.c-accordion__icon-bar {
  display: block;
  width: .75em;
  height: 2px;
  background: #001FA3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform .3s, opacity .3s;
}

.c-accordion__icon-bar:nth-child(1) {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(90deg);
}

.c-accordion.is-open .c-accordion__icon-bar:nth-child(1) {
  opacity: 0;
  transform: translate(-50%, -50%) rotate(0deg);
}

.c-accordion__content {
  display: none;
  line-height: 2em;
  margin-top: 1em;
  font-size: .9em;
}

.c-accordion__content p {
  margin-bottom: 0;
}

/* ==========================================================================
   #breadcrumbs
   ========================================================================== */
.c-breadcrumbs {
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
  opacity: 0.5;
  padding: 2em 0;
}

.c-breadcrumbs__container {
  position: absolute;
  top: 125px;
  left: 0;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 47.99em) {
  .c-breadcrumbs__container {
    top: 55px;
  }
}

.c-breadcrumbs--alternative {
  color: #171717;
}

.page-id-28 .c-breadcrumbs {
  color: #171717;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-button {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.88rem 2.9rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 300ms ease-in-out;
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
  text-transform: uppercase;
  font-family: "AFont", Arial;
  font-weight: 500;
  line-height: 2.5em;
  text-decoration: none;
  border: 0;
  border-radius: 0;
  transition: background-color 0.3s linear;
  /* Style variants
   ========================================================================== */
  /* Size variants
   ========================================================================== */
}

@media (max-width: 47.99em) {
  .c-button:not(.c-button--tertiary) {
    width: 100%;
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.38889;
    padding: 1.5em;
  }
}

.c-button:hover, .c-button:active, .c-button:focus {
  text-decoration: none;
}

.c-button__triangle * {
  fill: #001FA3;
}

.c-button__ico {
  font-size: 1.5em;
  font-weight: 400;
  margin-right: 10px;
}

.c-button--primary {
  background-color: #6DEED8;
  color: #171717;
}

.c-button--primary:hover, .c-button--primary:active {
  background-color: #fff;
  color: #171717;
}

.c-button--secondary {
  background-color: #001FA3;
  color: #fff;
}

.c-button--secondary:hover, .c-button--secondary:active {
  color: #171717;
  background-color: #6DEED8;
}

.c-button--tertiary {
  text-transform: none;
  color: #6DEED8;
  font-family: "AFont", Arial;
  font-weight: 500;
  padding: 0;
  line-height: 1.33rem;
  margin: 1em 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-size: 0.88889rem;
  line-height: 1.5625;
}

.c-button--tertiary:hover, .c-button--tertiary:active {
  color: #fff;
}

.c-button--outline {
  border: 1px solid #001FA3;
  background-color: transparent;
  color: #001FA3;
}

.c-button--outline:hover, .c-button--outline:active {
  background-color: #001FA3;
  color: #fff;
}

.c-button--alternative {
  padding: 0;
  padding-right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-size: 0.66667rem;
  line-height: 1.66667;
  line-height: 3em;
  color: #001FA3;
  letter-spacing: 1px;
}

@media (max-width: 47.99em) {
  .c-button--alternative span {
    max-width: calc( 100% - 40px);
  }
}

.c-button--alternative svg {
  position: relative;
  left: 0;
  transition: all 0.3s linear;
}

.c-button--alternative:hover svg, .c-button--alternative:active svg, .c-button--alternative:focus svg {
  left: 5px;
}

.c-button--read-more {
  position: absolute;
  bottom: 0;
  right: 0;
}

.c-button--small {
  padding: 0.25rem 0.5rem;
}

.c-button--large {
  padding: 0.75rem 1.5rem;
}

/* ==========================================================================
   #CHECKBOX
   ========================================================================== */
.c-checkbox {
  cursor: pointer;
  position: relative;
  padding-left: 53px;
  line-height: 34px;
  margin-bottom: 15px;
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
}

.c-checkbox.c-label--inline {
  padding-left: 58px;
}

.c-checkbox__replacement {
  display: inline-block;
  width: 34px;
  height: 34px;
  border: 1px solid #6DEED8;
  vertical-align: middle;
  margin-right: 10px;
  transition: all linear 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

.c-checkbox__replacement:before {
  content: "";
  display: block;
  position: absolute;
}

.c-checkbox.u-disabled .c-checkbox__replacement {
  box-shadow: none;
  border-color: #fff !important;
}

.c-checkbox:hover .c-checkbox__replacement {
  border-color: #fff;
}

.c-checkbox input:checked + .c-checkbox__replacement {
  background: #fff;
}

.c-checkbox input:checked + .c-checkbox__replacement:before, .c-checkbox input:checked + .c-checkbox__replacement:after {
  border: 1px solid #001FA3;
  background: #001FA3;
}

.c-checkbox__replacement {
  border-radius: 0;
}

.c-checkbox__replacement:before, .c-checkbox__replacement:after {
  content: "";
  display: block;
  position: absolute;
  height: 4px;
}

.c-checkbox__replacement:before {
  left: 4px;
  bottom: 16px;
  width: 14px;
  transform: rotate(45deg);
  transform-origin: bottom left;
}

.c-checkbox__replacement:after {
  left: 12px;
  bottom: 5px;
  width: 20px;
  transform: rotate(-50deg);
  transform-origin: top left;
}

.c-clients {
  padding: 6.44rem 0 0;
  background: #131313;
  position: relative;
}

.c-clients__logo {
  padding: 20px 0;
  text-align: center;
  margin-top: 1em;
}

.c-clients__logos {
  padding: 3em 0 4em;
  margin: 0;
}

@media (max-width: 47.99em) {
  .c-clients__logos {
    padding: 3em 0 0;
  }
}

@media (max-width: 47.99em) {
  .c-clients__logo {
    padding: 10px !important;
    padding-bottom: 0 !important;
  }
}

.c-clients__anim {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-contact-form {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #e0fffc;
}

@media (max-width: 63.99em) {
  .c-contact-form {
    padding-bottom: 30px;
  }
}

.c-contact-form__title {
  line-height: 1.2;
  font-size: 34px;
  font-size: 1.88889rem;
  line-height: 1.17647;
}

@media (max-width: 47.99em) {
  .c-contact-form__title {
    font-size: 26px;
    font-size: 1.44444rem;
    line-height: 1.19231;
  }
}

.c-contact-form__content {
  line-height: 2;
}

.c-contact-form__anim {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: auto;
  z-index: -1;
  pointer-events: none !important;
  z-index: 100;
}

@media (max-width: 63.99em) {
  .c-contact-form__anim {
    display: none;
  }
}

.c-contact-form__content-wrap {
  padding: 5rem 0;
}

@media (max-width: 63.99em) {
  .c-contact-form__content-wrap {
    padding: 2rem 0;
  }
}

.c-contact-form__content {
  color: #171717;
}

.c-contact-form__form-wrap {
  position: relative;
  padding: 2em;
}

.c-contact-form__form-wrap:before {
  content: '';
  display: block;
  background-color: #001FA3;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
  width: 100%;
}

@media (min-width: 64em) {
  .c-contact-form__form-wrap {
    padding: 3rem 0;
    padding-left: 2em;
    max-width: 400px;
  }
  .c-contact-form__form-wrap:before {
    width: 50vw;
  }
}

.c-contact-form__form-title {
  line-height: 1.2;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  background: #171717;
}

.c-footer__logo {
  padding: 3.33rem 0;
}

@media (max-width: 47.99em) {
  .c-footer__logo {
    padding-bottom: 15px;
  }
}

.c-footer__copy {
  padding: 4.22rem 0 3.33rem 0;
  margin: 0;
  opacity: 0.5;
  letter-spacing: 0.75px;
}

@media (max-width: 47.99em) {
  .c-footer__copy {
    padding-top: 15px;
  }
}

.c-footer__link {
  color: inherit;
  text-decoration: none;
}

.c-footer__link:hover {
  color: inherit;
}

/* ==========================================================================
   #FORM
   ========================================================================== */
.c-form {
  padding: 0 0 5rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: #001FA3;
}

@media (min-width: 48em) {
  .c-form {
    padding: 4rem 0 5rem;
  }
}

@media (max-width: 47.99em) {
  .c-form.c-section {
    padding-bottom: 0 !important;
  }
  .c-form.c-section:first-of-type {
    margin-top: -55px;
  }
}

.c-form__anim {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: auto;
  z-index: -1;
  pointer-events: none !important;
  z-index: 100;
}

@media (max-width: 63.99em) {
  .c-form__anim {
    display: none;
  }
}

.c-form__title, .c-form__subtitle {
  padding: 0 0 3rem;
}

.c-form__subtitle {
  line-height: 2rem !important;
}

.c-form__quotes {
  background: #171717;
  position: relative;
}

@media (min-width: 48em) {
  .c-form__quotes .c-form__subtitle {
    padding-left: 70px;
  }
}

.c-form__quotes:before, .c-form__quotes:after {
  content: "";
  display: block;
  position: absolute;
  top: -120px;
  left: 0;
  height: calc(100% + 300px);
}

.c-form__quotes:before {
  width: 50vw;
  background: #171717;
  z-index: -1;
}

.c-form__quotes:after {
  width: 30px;
  background: #001FA3;
}

@media (max-width: 63.99em) {
  .c-form__quotes {
    padding: 50px;
    padding-bottom: 100px;
    margin-top: 2rem;
  }
  .c-form__quotes .c-slider__pager {
    bottom: 50px;
  }
  .c-form__quotes:before, .c-form__quotes:after {
    display: none;
  }
}

@media (max-width: 47.99em) {
  .c-form__quotes {
    flex: 0 0 calc(100% + 30px) !important;
    width: calc(100% + 30px);
    max-width: calc(100% + 30px) !important;
  }
}

.c-form__slider {
  position: relative;
}

.c-form__slider-pager {
  margin: auto;
  left: 100px;
  right: 0;
  text-align: center;
  z-index: 1000 !important;
  position: relative;
}

.c-form__slider-pager .c-slider__page {
  margin-left: 70px;
}

@media (min-width: 48em) {
  .c-form__data {
    padding-left: 70px;
  }
}

.c-form__data-small {
  padding-top: 2em;
  opacity: 0.5;
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
  line-height: 2em;
}

.c-form__more-info {
  display: none;
}

.c-form__more-info.is-active {
  display: inline;
}

.wpcf7-form-control-wrap.acceptance-20 {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.c-filelist {
  margin: 0;
}

.c-filelist__label {
  padding-top: 0 !important;
}

.c-filelist__item {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-size: 0.88889rem;
  line-height: 1.5625;
  padding: 10px 14px;
  line-height: 1.5em;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 10px;
  position: relative;
}

.c-filelist__delete {
  color: #FF0050;
  font-size: 40px;
  font-size: 2.22222rem;
  line-height: 1.125;
  line-height: 24px;
  height: 16px;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
}

.grecaptcha-badge {
  z-index: 99999999999;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #001FA3;
  z-index: 99999999;
}

.c-header__wrapper {
  height: 125px;
  transition: height linear 0.2s;
}

@media (max-width: 47.99em) {
  .c-header {
    background: #171717;
  }
  .c-header__wrapper {
    height: 70px;
  }
}

.c-header.is-scrolled .c-header__wrapper {
  height: 70px;
}

.c-header.is-scrolled .c-header__wrapper img {
  height: 30px;
}

@media (max-width: 47.99em) {
  .c-header__nav {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    z-index: 9999;
    background: #fff;
    color: #171717;
    justify-content: center;
    padding: 30px 0;
    transition: left linear 0.3s;
    height: calc(100vh - 70px);
  }
  .c-header__nav.is-active {
    left: 0;
  }
}

.c-header__nav--trigger line {
  pointer-events: none;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
.c-heading {
  position: relative;
  font-weight: 500;
  color: #171717;
  font-family: "AFont", Arial;
  margin-bottom: 0;
  z-index: 1;
}

.c-heading--hero {
  font-size: 60px;
  font-size: 3.33333rem;
  line-height: 1.16667;
}

@media (max-width: 47.99em) {
  .c-heading--hero {
    font-size: 38.5px;
    font-size: 2.13889rem;
    line-height: 1.12987;
  }
}

.c-heading--primary {
  font-size: 48px;
  font-size: 2.66667rem;
  line-height: 1.10417;
}

@media (max-width: 47.99em) {
  .c-heading--primary {
    font-size: 31px;
    font-size: 1.72222rem;
    line-height: 1.16129;
  }
}

.c-heading--secondary {
  font-size: 40px;
  font-size: 2.22222rem;
  line-height: 1.325;
}

@media (max-width: 47.99em) {
  .c-heading--secondary {
    font-size: 26px;
    font-size: 1.44444rem;
    line-height: 1.19231;
  }
}

.c-heading--blog {
  font-size: 34px;
  font-size: 1.88889rem;
  line-height: 1.41176;
}

.c-heading--tertiary {
  font-size: 24px;
  font-size: 1.33333rem;
  line-height: 1.08333;
}

.c-heading--with-line {
  padding-bottom: 1em;
  margin-bottom: 1.8em;
  position: relative;
}

.c-heading--with-line:after {
  content: "";
  width: 100px;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #6DEED8;
  transition: all 0.2s linear;
}

.c-heading--with-line-2:after {
  background: #001FA3;
}

.c-hero {
  padding-bottom: 11.1rem;
  position: relative;
}

@media (max-width: 63.99em) {
  .c-hero {
    padding-bottom: 2rem;
  }
}

@media (max-width: 47.99em) {
  .c-hero.c-section {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 47.99em) {
  .c-hero {
    padding-bottom: 0;
  }
}

.c-hero__title {
  margin-top: 1.5em;
  margin-bottom: 0.83em;
}

@media (max-width: 63.99em) {
  .c-hero__title {
    margin-top: 0.83em;
  }
}

.c-hero__desc {
  line-height: 2em;
  margin-bottom: 1em;
}

@media (max-width: 47.99em) {
  .c-hero__desc br {
    display: none;
  }
}

.c-hero__graph {
  position: absolute;
  width: calc(100% + 16px);
  height: auto;
  bottom: -4px;
  right: -10px;
  z-index: -1;
}

.c-hero__graph circle,
.c-hero__graph line {
  position: relative;
}

.c-hero__graph circle {
  z-index: 1;
}

.c-hero__graph line {
  z-index: 0;
}

@media (max-width: 47.99em) {
  .c-hero__graph {
    width: 200%;
    bottom: -15px;
    left: -80%;
    position: relative;
  }
}

.c-hero__triangles {
  position: absolute;
  width: 1920px;
  top: 0;
  left: 50%;
  margin-left: -960px;
  pointer-events: none;
  z-index: 9999;
}

@media (max-width: 47.99em) {
  .c-hero__triangles {
    top: -43px;
    display: none;
  }
}

.c-hero__triangles .c-triangle {
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
}

.c-hero__triangles .c-triangle.is-active {
  opacity: 1;
}

@media (max-width: 99.99em) {
  .c-hero__triangles .c-triangle:nth-of-type(2) {
    display: none;
  }
}

.c-hero__logo-text {
  max-width: 300px;
  border-left: 1px solid #fff;
  padding: 10px;
}

/* ==========================================================================
   #Icons
   ========================================================================== */
.c-icon-list {
  padding-top: 20px;
}

.c-icons {
  margin-top: 60px;
  position: relative;
  align-items: center;
  font-size: 16px;
  font-size: 0.88889rem;
  line-height: 1.5625;
}

.c-icons:before {
  position: absolute;
  top: 0;
  left: 20%;
  content: "";
  display: block;
  width: 1px;
  background: #d8d8d8;
  height: 100%;
}

@media (max-width: 47.99em) {
  .c-icons:before {
    display: none;
  }
}

.c-icons img {
  max-height: 80px;
  margin: 25px 0;
}

/* ==========================================================================
   #Icons
   ========================================================================== */
.c-images {
  padding: 60px 0;
  position: relative;
  z-index: 1;
}

.c-images__bg {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  z-index: -1;
}

.c-images .o-wrapper > * {
  margin-bottom: 20px;
}

.c-images .o-wrapper > *:not(:first-child) {
  margin-top: 40px;
}

.c-images .c-embed--video {
  padding-top: 56.25%;
  position: relative;
}

.c-images .c-embed--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   #INPUT
   ========================================================================== */
.c-input {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font: inherit;
  outline: none;
  border: 2px solid #fff;
  color: #171717;
  background: #fff;
  transition: all 0.3s linear;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none !important;
  -webkit-appearance: none;
  padding: 10px 15px;
  line-height: 1.3em;
  margin-bottom: 20px;
  transition: all linear 0.2s;
}

.c-input--disabled {
  border-style: dashed;
  pointer-events: none;
  color: #171717;
}

.c-input--disabled:hover {
  border-color: #fff;
}

.c-input::placeholder {
  color: rgba(23, 23, 23, 0.5);
}

.c-input:focus, .c-input:active {
  border-color: #6DEED8;
}

/* ==========================================================================
   #LABEL
   ========================================================================== */
.c-label {
  padding-top: 5px;
  display: block;
}

.c-label__title {
  color: #6DEED8;
  font-weight: 500;
  font-family: "AFont", Arial;
  font-size: 16px;
  font-size: 0.88889rem;
  line-height: 1.5625;
  line-height: 1.5em;
  margin-bottom: 10px;
  display: inline-block;
}

@media (max-width: 47.99em) {
  .c-label__title {
    font-size: 16px;
  }
}

.c-label__error {
  font-size: 16px;
  font-size: 0.88889rem;
  line-height: 1.5625;
  line-height: 1.2em;
  margin-top: 10px;
  display: inline-block;
  color: #fff;
}

.c-label--required .c-label__title:after {
  content: "*";
}

.c-label--checkbox .c-label__title, .c-label--radio .c-label__title {
  position: static;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
  line-height: 1.5em;
}

.c-label--inline {
  display: inline-block;
  margin-right: 10px;
}

.c-label--inline.c-label--checkbox, .c-label--inline.c-label--radio {
  margin-bottom: 0;
  padding-top: 0;
}

.c-label--inline.c-label--checkbox .c-label__title, .c-label--inline.c-label--radio .c-label__title {
  font-size: 20px;
  font-size: 1.11111rem;
  line-height: 1.25;
  line-height: 34px;
  margin-bottom: 0;
}

.c-label--bigger {
  font-size: 16px !important;
  font-size: 0.88889rem !important;
  line-height: 1.5625 !important;
  color: #6DEED8;
}

.c-logo {
  height: 100px;
  max-height: 100px;
  line-height: 100px;
}

.c-logos {
  color: #171717;
  padding: 3rem 0;
  background: #F7F7F7;
  position: relative;
  z-index: 2;
}

.c-logos__desc {
  font-family: "AFont", Arial;
  font-weight: 500;
  font-size: 16px;
}

.c-logo img {
  max-height: 100px;
  width: auto;
  max-width: 90%;
  height: auto;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  margin: 0;
}

@media (max-width: 47.99em) {
  .c-main-nav {
    display: block;
  }
}

.c-main-nav__item {
  font-size: 16px;
  font-size: 0.88889rem;
  line-height: 1.5625;
  list-style: none;
  margin: 0;
  margin-left: 3em;
  padding: 0;
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

@media (max-width: 47.99em) {
  .c-main-nav__item {
    flex: 0 0 100%;
    text-align: center;
    margin-left: 0 !important;
    padding: 10px 15px;
    font-size: 16px;
  }
}

.c-main-nav__link {
  display: block;
  text-decoration: none;
  color: #fff;
  position: relative;
  position: relative;
}

.c-main-nav__link:after {
  content: "";
  width: 0%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 50%;
  background: #6DEED8;
  transition: all 0.2s linear;
}

@media (max-width: 47.99em) {
  .c-main-nav__link {
    color: #171717;
  }
}

.c-main-nav__link:hover,
.current-menu-item .c-main-nav__link {
  color: #fff;
  position: relative;
}

.c-main-nav__link:hover:after,
.current-menu-item .c-main-nav__link:after {
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0%;
  background: #6DEED8;
  transition: all 0.2s linear;
}

@media (max-width: 47.99em) {
  .c-main-nav__link:hover,
  .current-menu-item .c-main-nav__link {
    color: #171717;
  }
}

.c-offer-form {
  padding: 4rem 0;
  background: #171717;
  position: relative;
  z-index: 2;
}

.c-offer-form__form {
  margin-top: 3.33rem;
  margin-bottom: 4em;
}

.c-offer-form__desc {
  color: rgba(255, 255, 255, 0.5);
}

.c-offer-form__desc .o-link {
  color: #fff;
}

.c-offer-form__option {
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0.9rem;
  margin-bottom: 1.6rem;
}

@media (max-width: 47.99em) {
  .c-offer-form__option {
    margin-bottom: 0.6rem;
  }
}

.c-offer-form__options {
  margin-bottom: 1.1rem;
}

.c-offer {
  margin-bottom: 3rem;
}

@media (max-width: 47.99em) {
  .c-offer {
    text-align: center;
  }
}

.c-offers {
  color: #171717;
  padding: 5rem 0;
  background: #F7F7F7;
  position: relative;
  z-index: 2;
}

.c-offers__desc {
  line-height: 2em;
}

.c-offer__anim {
  position: absolute;
  top: 0;
  left: -10px;
  width: 400px;
  height: 200px;
  pointer-events: none;
  z-index: 1;
}

@media (max-width: 47.99em) {
  .c-offer__anim {
    width: 200%;
    height: 160px;
  }
}

.c-offer__ico {
  margin-bottom: 3.5rem;
  height: 155px;
  padding: 38px 0 0 15px;
  position: relative;
}

.c-offer__ico img {
  position: relative;
  z-index: 2;
}

@media (max-width: 47.99em) {
  .c-offer__ico {
    width: 50%;
    margin: auto;
  }
}

.c-offer__title {
  font-family: "AFont", Arial;
  font-weight: 500;
  margin-bottom: 1em;
}

.c-offer__desc {
  color: #616161;
}

.c-offer__container {
  margin-bottom: 2rem;
}

@media (min-width: 48em) {
  .c-offer--inline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0rem;
  }
}

.c-offer--inline .c-offer__ico {
  width: 210px;
  flex: 0 0 210px;
}

.c-offer--single {
  align-items: center;
}

.c-offer--single .c-offer__desc {
  color: inherit;
  padding: 15px 0;
}

.c-offer--single .c-offer__subtitle {
  color: #757575;
  font-family: "AFont", Arial;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.c-offer--single .c-offer__ico {
  width: 155px;
  margin-bottom: 40px;
}

@media (min-width: 64em) {
  .c-offer--single .c-offer__ico {
    margin: auto;
    margin-bottom: 0;
  }
}

.c-single-offer {
  padding: 50px 0;
}

@media (min-width: 64em) {
  .c-single-offer {
    padding: 120px 0;
  }
}

@media (min-width: 64em) {
  .c-single-offer--right .c-offer--single > div:first-child {
    order: 2;
  }
}

.c-page-header {
  padding-bottom: 5rem;
  position: relative;
  overflow: hidden;
  background: #171717;
}

@media (max-width: 47.99em) {
  .c-page-header {
    padding-top: 0 !important;
  }
}

.c-page-header__anim {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: auto;
  z-index: -1;
  pointer-events: none !important;
  z-index: 100;
}

.c-page-header__title {
  margin-top: 1.5em;
  margin-bottom: 0.83em;
}

.c-page-header__desc {
  line-height: 2em;
  margin-bottom: 3em;
}

@media (max-width: 47.99em) {
  .c-page-header__desc {
    margin-bottom: 0;
  }
}

.c-page-header__image {
  position: relative;
  z-index: 150;
}

.c-page-header__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 47.99em) {
  .c-page-header__image-container {
    order: -1;
    margin-top: 1rem;
  }
}

body.error404 .c-page-header__image-container {
  min-height: 630px;
}

.c-page-header--static {
  padding-top: 50px;
}

.c-content {
  position: relative;
  z-index: 2;
  border-top: 1px solid #d2d2d2;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  color: #5C5C5C;
  font-size: 16px;
}

.c-content > div:first-child {
  padding-left: 0;
  padding-right: 30px;
}

.c-content__subtitle {
  font-size: 14px;
}

.c-content--bg {
  background: #F7F7F7;
  position: relative;
  z-index: 1;
}

.c-content--bg:before {
  content: "";
  display: block;
  position: absolute;
  top: -75px;
  right: 0;
  background: #F7F7F7;
  height: 200vh;
  width: 50vw;
  z-index: -1;
}

@media (max-width: 47.99em) {
  .c-content--1 {
    border: 0;
  }
}

.c-content__button {
  z-index: 100;
  position: relative;
}

#promo-banner {
  padding-bottom: 0;
}

#promo-banner .c-page-header__title {
  font-size: 40px !important;
}

#promo-banner .c-page-header__anim {
  transform: scale(0.6) translate(12%, 0);
  transform-origin: top center;
}

/* ==========================================================================
   #person
   ========================================================================== */
.c-person {
  position: relative;
  margin-bottom: 2rem;
}

.c-person__anim {
  position: absolute;
  top: 0;
  left: 0;
}

.c-person__subscription {
  background: #fff;
  padding: 63px 30px 34px;
}

.c-person__pic {
  text-align: center;
  margin-bottom: -30px;
}

.c-person__pic img {
  height: 400px;
}

.c-person__title {
  font-family: "AFont", Arial;
  font-weight: 500;
}

.c-person__desc {
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
  color: #616161;
  line-height: 1.5em;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post-archive-header {
  background: #fff;
  color: #171717;
  padding-bottom: 50px;
}

.c-post-archive-header .c-post__desc {
  position: relative;
  line-height: 2rem;
  font-size: 1rem;
  padding-bottom: 60px;
}

.c-post-archive-header .c-post__desc .c-button {
  bottom: 10px;
}

@media (max-width: 47.99em) {
  .c-post-archive-header {
    padding: 0 !important;
  }
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post-archive {
  padding: 70px 0 85px;
}

.c-post-archive .c-tease {
  margin-top: 0;
}

.c-post-archive__content {
  padding-top: 65px;
  overflow: hidden;
}

.c-post-archive__button {
  padding-top: 75px;
  margin-bottom: 0;
}

@media (max-width: 47.99em) {
  .post-type-archive-offer .c-page-header__image-container {
    display: none;
  }
  .post-type-archive-offer .c-page-header__title {
    margin-top: auto;
  }
  .c-page-header__title:first-of-type {
    margin-top: auto;
  }
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  padding: 2em 0 0;
  position: relative;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-post:after {
  content: "";
  display: block;
  background: #d2d2d2;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -110px;
}

.c-post__content {
  padding: 50px 0 0;
  line-height: 2rem;
}

@media (min-width: 48em) {
  .c-post__content {
    padding: 50px 50px 0;
  }
}

.c-post__content > * {
  margin-bottom: 40px;
}

.editor-block-list__layout h1:not(.c-heading),
.editor-block-list__layout h2:not(.c-heading),
.editor-block-list__layout h3:not(.c-heading),
.editor-block-list__layout h4:not(.c-heading),
.editor-block-list__layout h5:not(.c-heading),
.editor-block-list__layout h6:not(.c-heading),
.s-wysiwyg h1:not(.c-heading),
.s-wysiwyg h2:not(.c-heading),
.s-wysiwyg h3:not(.c-heading),
.s-wysiwyg h4:not(.c-heading),
.s-wysiwyg h5:not(.c-heading),
.s-wysiwyg h6:not(.c-heading) {
  font-family: "ObjectSans", Arial;
  font-weight: 400;
  font-size: 24px !important;
  font-size: 1.33333rem !important;
  margin-bottom: 15px;
}

.editor-block-list__layout ul,
.editor-block-list__layout ol,
.editor-block-list__layout dd,
.s-wysiwyg ul,
.s-wysiwyg ol,
.s-wysiwyg dd {
  margin-left: 1.5rem;
}

.editor-block-list__layout ul li,
.editor-block-list__layout ul dl,
.editor-block-list__layout ol li,
.editor-block-list__layout ol dl,
.editor-block-list__layout dd li,
.editor-block-list__layout dd dl,
.s-wysiwyg ul li,
.s-wysiwyg ul dl,
.s-wysiwyg ol li,
.s-wysiwyg ol dl,
.s-wysiwyg dd li,
.s-wysiwyg dd dl {
  padding-left: 0.5rem;
}

@media (min-width: 48em) {
  .editor-block-list__layout .wp-block-embed,
  .s-wysiwyg .wp-block-embed {
    margin-left: -50px;
    margin-right: -50px;
  }
}

.editor-block-list__layout .wp-block-embed .c-embed--video,
.s-wysiwyg .wp-block-embed .c-embed--video {
  padding-top: 56.25%;
  position: relative;
}

.editor-block-list__layout .wp-block-embed .c-embed--video iframe,
.s-wysiwyg .wp-block-embed .c-embed--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editor-block-list__layout a,
.s-wysiwyg a {
  color: #001fa3;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

@media (max-width: 47.99em) {
  .ssba > div {
    padding: 5px 5px !important;
  }
  .ssba > div .ssbp-list li {
    margin-left: 0 !important;
  }
}

/* ==========================================================================
   #Quote
   ========================================================================== */
.c-quote {
  background: url(../assets/images/quote.svg) no-repeat top left;
  padding-top: 60px;
  color: #fff;
}

@media (min-width: 48em) {
  .c-quote {
    background: url(../assets/images/quote.svg) no-repeat top left 70px;
  }
}

.c-quote--alternative {
  background-position: top center;
}

.c-quote--alternative .c-quote__image {
  width: 60px;
  height: 60px;
}

.c-quote--alternative .c-quote__author {
  font-size: .8em;
  color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 48em) {
  .c-quote {
    padding-left: 70px;
  }
}

.c-quote__desc {
  line-height: 2em;
}

.c-quote__subscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-size: 0.88889rem;
  line-height: 1.5625;
  margin-top: 2em;
}

.c-quote__author {
  margin-left: 1em;
}

.c-quote__image {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

/* ==========================================================================
   #RADIO BUTTON
   ========================================================================== */
.c-radio {
  cursor: pointer;
  position: relative;
  padding-left: 53px;
  line-height: 34px;
  margin-bottom: 15px;
}

.c-radio.c-label--inline {
  padding-left: 58px;
}

.c-radio__replacement {
  display: inline-block;
  width: 34px;
  height: 34px;
  border: 1px solid #6DEED8;
  vertical-align: middle;
  margin-right: 10px;
  transition: all linear 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}

.c-radio__replacement:before {
  content: "";
  display: block;
  position: absolute;
}

.c-radio.u-disabled .c-radio__replacement {
  box-shadow: none;
  border-color: #fff !important;
}

.c-radio:hover .c-radio__replacement {
  border-color: #fff;
}

.c-radio input:checked + .c-radio__replacement {
  background: #fff;
}

.c-radio input:checked + .c-radio__replacement:before, .c-radio input:checked + .c-radio__replacement:after {
  border: 1px solid #001FA3;
  background: #001FA3;
}

.c-radio__replacement {
  border-radius: 17px;
}

.c-radio__replacement:before {
  content: "";
  display: block;
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 9px;
  left: 7px;
  top: 7px;
}

.c-related-posts {
  background: #F7F7F7;
  padding: 2rem;
  margin-bottom: 75px;
}

.c-results {
  overflow: hidden;
  padding: 3.5rem 0 5rem;
}

.c-results__content {
  padding-right: 30px;
}

@media (max-width: 63.99em) {
  .c-results__content {
    position: relative;
    z-index: 2;
    background: #fff;
    box-shadow: 50vw -50vw 0px 50vw #fff;
  }
}

.c-results__form {
  padding-left: 4.8rem !important;
  background: #001FA3;
  box-shadow: 50vw 0px 0px 50vw #001FA3;
}

@media (max-width: 63.99em) {
  .c-results__form {
    padding-top: 40px;
    padding-left: 30px !important;
  }
}

.kg-cookie-content {
  position: fixed;
  bottom: 0;
  text-align: center;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999999;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kg-cookie-content .content {
  padding: 5px;
}

.kg-cookie-content .content #cookieAccept,
.kg-cookie-content .content #cookieNoAccept {
  z-index: 9999;
}

.kg-cookie-content p {
  margin: 0;
}

#kg_gdpr.modal {
  display: none;
  position: fixed;
  left: 15px;
  bottom: 78px;
  overflow: auto;
  z-index: 999999999;
}

#kg_gdpr.modal .modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 300px;
  z-index: 9999999;
  color: #000;
  font-size: 12px;
}

#kg_gdpr.modal .modal-content .gdpr_text {
  padding: 0 1em;
}

#kg_gdpr.modal .modal-content .gdpr_text a {
  text-decoration: underline;
}

#kg_gdpr.modal .modal-content .kg_rodo_btns {
  text-align: center;
}

#kg_gdpr.modal .modal-content .kg_rodo_btns .kg_btn {
  margin-bottom: 10px;
}

#kg_gdpr.modal .modal-content .close {
  color: #ff0050;
  font-size: 2.22222rem;
  line-height: 24px;
  height: 24px;
  padding-top: 5px;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 0 !important;
  right: 0;
}

#kg_gdpr.modal .modal-content .close:hover, #kg_gdpr.modal .modal-content .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.kg_btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0 5px;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.2rem 2.9rem;
  transition: all 300ms ease-in-out;
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
  text-transform: uppercase;
  font-family: "AFont", Arial;
  font-weight: 500;
  line-height: 2.5em;
  text-decoration: none;
  border: 0;
  border-radius: 0;
  transition: background-color 0.3s linear;
  background-color: #001fa3;
  color: white;
}

.kg_btn:hover, .kg_btn:active, .kg_btn:focus {
  text-decoration: none;
}

.kg_btn:hover, .kg_btn:active, .kg_btn:focus {
  background-color: #fff;
  color: #171717;
}

.kg_btn_noaccept {
  display: none;
}

.kg_gdpr_popup #kg_gdpr {
  position: fixed !important;
  left: 2% !important;
  bottom: 2% !important;
  width: 300px;
  height: 370px;
}

.kg_gdpr_popup #kg_gdpr.modal .modal-content {
  width: auto;
  max-width: none;
  border: none;
  padding: 0 2% !important;
  padding-top: 35px !important;
}

.gdpr_text {
  max-height: 250px !important;
}

@media only screen and (max-width: 768px) {
  #kg_gdpr.modal {
    left: auto !important;
    bottom: auto !important;
    position: fixed !important;
    top: 24%;
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  #kg_gdpr.modal .modal-content {
    max-height: 60vh;
    overflow: scroll;
    margin: 0% auto;
  }
}

.c-score {
  background: #fff;
  color: #171717;
  padding: 4rem 1.5rem 1.5rem 4rem;
  margin-bottom: 4.2rem;
  position: relative;
  box-shadow: 45px 45px 0px 0px rgba(255, 255, 255, 0.1);
  z-index: 1;
}

@media (max-width: 47.99em) {
  .c-score {
    padding: 6rem 1.5rem 1.5rem;
  }
}

.c-score__logo {
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0.7;
}

.c-score__number {
  font-size: 48px;
  font-size: 2.66667rem;
  line-height: 1.14583;
  line-height: 1.5rem;
}

.c-score__desc {
  max-width: 14.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1.66rem;
}

.c-score__anim {
  position: absolute;
  top: -25%;
  left: -25%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  transform: scale(0);
  transition: all 2s linear;
}

.c-score__anim.is-active {
  top: 0;
  left: 0;
  transform: scale(1);
}

@media (min-width: 48em) {
  .c-score--inline {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    min-height: 128px;
  }
  .c-score--inline .c-score__number {
    padding: 0 60px 0 40px;
    font-size: 36px;
    min-width: 260px;
  }
  .c-score--inline .c-score__desc {
    margin: 0;
    max-width: none;
    min-height: 1px;
  }
  .c-score--inline .c-score__anim {
    width: 450px;
    height: 250px;
    transform: scale(1);
    top: 0;
    left: 0;
  }
}

.c-scores {
  background: #171717;
  padding: 5.55rem 0;
  position: relative;
  z-index: 1;
}

@media (max-width: 47.99em) {
  .c-scores {
    padding-bottom: 30px;
  }
}

.c-scores__container {
  padding: 5.55rem 0 4.5rem;
}

.c-scores__bg {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: auto;
  pointer-events: none;
  z-index: -1;
}

.c-scores__filter {
  text-transform: uppercase;
  padding: 16px 5px;
  line-height: 2rem;
  font-family: "AFont", Arial;
  font-weight: 500;
  font-size: 14px;
  flex: 1;
  border: 1px solid #6DEED8;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s linear;
}

.c-scores__filters {
  display: flex;
  margin: -150px 0 -10px;
}

@media (max-width: 63.99em) {
  .c-scores__filters {
    flex-wrap: wrap;
  }
}

@media (max-width: 47.99em) {
  .c-scores__filters {
    margin: 0;
  }
}

@media (max-width: 63.99em) {
  .c-scores__filter {
    flex: 0 0 50%;
  }
}

.c-scores__filter.is-active, .c-scores__filter:hover {
  background: #6DEED8;
  color: #171717;
}

@media (min-width: 64em) {
  .c-scores__filter:not(:first-child) {
    border-left: 0;
  }
}

@media (max-width: 63.99em) {
  .c-scores__filter {
    flex: 0 0 50%;
  }
}

@media (max-width: 47.99em) {
  .c-scores__filter {
    flex: 0 0 100%;
  }
}

.c-scores--lower {
  padding: 80px 0 50px;
}

.c-scores--lower .c-scores__container {
  padding-bottom: 0;
}

.c-scores-slider {
  color: #fff;
  padding: 5rem 0;
  background: #171717;
  position: relative;
  z-index: 2;
}

.c-scores-slider__wrap {
  margin-top: 3rem;
}

.c-scores-slider__pager {
  bottom: 25px !important;
  right: 0 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.c-scores-slider__pager .c-slider__page {
  margin-left: 30px;
}

@media (max-width: 63.99em) {
  .c-scores-slider__pager .c-slider__page {
    margin-left: 20px;
  }
}

.c-scores-slider__anim {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: auto;
  pointer-events: none !important;
}

@media (max-width: 47.99em) {
  .c-section {
    padding: 30px 0 !important;
  }
  .c-section:first-of-type {
    margin-top: -55px;
  }
  .c-section.c-post-archive-header {
    padding: 0 !important;
  }
}

/* ==========================================================================
   #SELECT
   ========================================================================== */
.c-select__title {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font: inherit;
  outline: none;
  border: 2px solid #fff;
  color: #171717;
  background: #fff;
  transition: all 0.3s linear;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none !important;
  -webkit-appearance: none;
  text-align: left;
  border: 0;
  border-bottom: 1px solid #fff;
  padding: 15px 0;
  cursor: pointer;
  position: relative;
}

.c-select__title--disabled {
  border-style: dashed;
  pointer-events: none;
  color: #171717;
}

.c-select__title--disabled:hover {
  border-color: #fff;
}

.c-select__title::placeholder {
  color: rgba(23, 23, 23, 0.5);
}

.c-select__title:focus, .c-select__title:active {
  border-color: #6DEED8;
}

.c-select__title:before, .c-select__title:after {
  content: "";
  display: block;
  position: absolute;
  height: 4px;
}

.c-select__title:before, .c-select__title:after {
  background: #001FA3;
}

.c-select__title:before {
  left: calc(100% - 20px);
  bottom: 24px;
  width: 10px;
  transform: rotate(45deg);
  transform-origin: top left;
}

.c-select__title:after {
  left: calc(100% - 14px);
  bottom: 17px;
  width: 10px;
  transform: rotate(-45deg);
  transform-origin: top left;
}

.c-select__list {
  border: 1px solid #171717;
  border-radius: 3px;
  padding: 20px;
  margin-top: -1px;
  position: relative;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #001FA3;
}

.c-select__list.is-open {
  opacity: 1;
  pointer-events: all;
}

.c-select__item {
  line-height: 2em;
  cursor: pointer;
}

.c-select__item:hover {
  color: #001FA3;
}

.c-select__item:first-child {
  display: none;
}

.c-select__item.is-selected {
  color: #001FA3;
}

.u-disabled .c-select .c-select__title {
  border-style: dashed;
  pointer-events: none;
  color: #171717;
}

.u-disabled .c-select .c-select__title:hover {
  border-color: #fff;
}

.c-sidebar {
  padding-top: 2em;
}

@media (min-width: 64em) {
  .c-sidebar {
    padding-left: 70px;
  }
}

.c-sidebar__heading {
  margin-bottom: 35px;
}

.c-sidebar__item {
  margin-bottom: 100px;
}

.c-slider__container {
  position: relative;
  align-self: stretch;
}

.c-slider__pager {
  position: absolute;
  bottom: -60px;
  right: 30px;
  z-index: 5;
}

.c-slider__pager--alternative .c-slider__page {
  background: #6DEED8;
}

@media (max-width: 47.99em) {
  .c-slider__pager {
    left: 0;
    right: 0;
  }
}

.c-slider__page {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #001FA3;
  opacity: 0.3;
  display: inline-block;
  margin-left: 60px;
  cursor: pointer;
  transition: opacity linear 0.2s;
}

.c-slider__page:first-child {
  margin-left: 0;
}

.c-slider__page.is-active {
  opacity: 1;
}

.c-slider__page:hover {
  opacity: 0.7;
}

.c-slider--standard {
  display: flex;
  overflow: hidden;
}

.c-slider--standard .c-slider__slide {
  flex: 0 0 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 0 32px;
}

.c-slider--standard .c-slider__slide.is-active {
  order: -1;
}

.c-slider.c-slider--standard[data-slides-visible="1"] .c-slider__slide {
  flex: 0 0 100%;
  max-width: 100%;
  min-width: 100%;
}

.c-slider.c-slider--standard[data-slides-visible="2"] .c-slider__slide {
  flex: 0 0 50%;
  max-width: 50%;
  min-width: 50%;
}

.c-slider.c-slider--standard[data-slides-visible="3"] .c-slider__slide {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  min-width: 33.33333%;
}

.c-slider.c-slider--standard[data-slides-visible="4"] .c-slider__slide {
  flex: 0 0 25%;
  max-width: 25%;
  min-width: 25%;
}

.c-slider.c-slider--standard[data-slides-visible="5"] .c-slider__slide {
  flex: 0 0 20%;
  max-width: 20%;
  min-width: 20%;
}

.c-slider.c-slider--standard[data-slides-visible="6"] .c-slider__slide {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
  min-width: 16.66667%;
}

.c-slider.c-slider--standard[data-slides-visible="7"] .c-slider__slide {
  flex: 0 0 14.28571%;
  max-width: 14.28571%;
  min-width: 14.28571%;
}

.c-slider.c-slider--standard[data-slides-visible="8"] .c-slider__slide {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  min-width: 12.5%;
}

.c-slider.c-slider--standard[data-slides-visible="9"] .c-slider__slide {
  flex: 0 0 11.11111%;
  max-width: 11.11111%;
  min-width: 11.11111%;
}

.c-slider.c-slider--standard[data-slides-visible="10"] .c-slider__slide {
  flex: 0 0 10%;
  max-width: 10%;
  min-width: 10%;
}

.c-slider.c-slider--standard[data-slides-visible="11"] .c-slider__slide {
  flex: 0 0 9.09091%;
  max-width: 9.09091%;
  min-width: 9.09091%;
}

.c-slider.c-slider--standard[data-slides-visible="12"] .c-slider__slide {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  min-width: 8.33333%;
}

.c-slider.c-slider--standard[data-slides-visible="13"] .c-slider__slide {
  flex: 0 0 7.69231%;
  max-width: 7.69231%;
  min-width: 7.69231%;
}

.c-slider.c-slider--standard[data-slides-visible="14"] .c-slider__slide {
  flex: 0 0 7.14286%;
  max-width: 7.14286%;
  min-width: 7.14286%;
}

.c-slider.c-slider--standard[data-slides-visible="15"] .c-slider__slide {
  flex: 0 0 6.66667%;
  max-width: 6.66667%;
  min-width: 6.66667%;
}

.c-slider.c-slider--standard[data-slides-visible="16"] .c-slider__slide {
  flex: 0 0 6.25%;
  max-width: 6.25%;
  min-width: 6.25%;
}

.c-slider.c-slider--standard[data-slides-visible="17"] .c-slider__slide {
  flex: 0 0 5.88235%;
  max-width: 5.88235%;
  min-width: 5.88235%;
}

.c-slider.c-slider--standard[data-slides-visible="18"] .c-slider__slide {
  flex: 0 0 5.55556%;
  max-width: 5.55556%;
  min-width: 5.55556%;
}

.c-slider.c-slider--standard[data-slides-visible="19"] .c-slider__slide {
  flex: 0 0 5.26316%;
  max-width: 5.26316%;
  min-width: 5.26316%;
}

.c-slider.c-slider--standard[data-slides-visible="20"] .c-slider__slide {
  flex: 0 0 5%;
  max-width: 5%;
  min-width: 5%;
}

.c-slider.c-slider--standard[data-slides-visible="21"] .c-slider__slide {
  flex: 0 0 4.7619%;
  max-width: 4.7619%;
  min-width: 4.7619%;
}

.c-slider.c-slider--standard[data-slides-visible="22"] .c-slider__slide {
  flex: 0 0 4.54545%;
  max-width: 4.54545%;
  min-width: 4.54545%;
}

.c-slider.c-slider--standard[data-slides-visible="23"] .c-slider__slide {
  flex: 0 0 4.34783%;
  max-width: 4.34783%;
  min-width: 4.34783%;
}

.c-slider.c-slider--standard[data-slides-visible="24"] .c-slider__slide {
  flex: 0 0 4.16667%;
  max-width: 4.16667%;
  min-width: 4.16667%;
}

.c-slider.c-slider--standard[data-slides-visible="25"] .c-slider__slide {
  flex: 0 0 4%;
  max-width: 4%;
  min-width: 4%;
}

.c-slider.c-slider--standard[data-slides-visible="26"] .c-slider__slide {
  flex: 0 0 3.84615%;
  max-width: 3.84615%;
  min-width: 3.84615%;
}

.c-slider.c-slider--standard[data-slides-visible="27"] .c-slider__slide {
  flex: 0 0 3.7037%;
  max-width: 3.7037%;
  min-width: 3.7037%;
}

.c-slider.c-slider--standard[data-slides-visible="28"] .c-slider__slide {
  flex: 0 0 3.57143%;
  max-width: 3.57143%;
  min-width: 3.57143%;
}

.c-slider.c-slider--standard[data-slides-visible="29"] .c-slider__slide {
  flex: 0 0 3.44828%;
  max-width: 3.44828%;
  min-width: 3.44828%;
}

.c-slider.c-slider--standard[data-slides-visible="30"] .c-slider__slide {
  flex: 0 0 3.33333%;
  max-width: 3.33333%;
  min-width: 3.33333%;
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="1"] .c-slider__slide {
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="2"] .c-slider__slide {
    flex: 0 0 50%;
    max-width: 50%;
    min-width: 50%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="3"] .c-slider__slide {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    min-width: 33.33333%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="4"] .c-slider__slide {
    flex: 0 0 25%;
    max-width: 25%;
    min-width: 25%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="5"] .c-slider__slide {
    flex: 0 0 20%;
    max-width: 20%;
    min-width: 20%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="6"] .c-slider__slide {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    min-width: 16.66667%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="7"] .c-slider__slide {
    flex: 0 0 14.28571%;
    max-width: 14.28571%;
    min-width: 14.28571%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="8"] .c-slider__slide {
    flex: 0 0 12.5%;
    max-width: 12.5%;
    min-width: 12.5%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="9"] .c-slider__slide {
    flex: 0 0 11.11111%;
    max-width: 11.11111%;
    min-width: 11.11111%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="10"] .c-slider__slide {
    flex: 0 0 10%;
    max-width: 10%;
    min-width: 10%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="11"] .c-slider__slide {
    flex: 0 0 9.09091%;
    max-width: 9.09091%;
    min-width: 9.09091%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="12"] .c-slider__slide {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    min-width: 8.33333%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="13"] .c-slider__slide {
    flex: 0 0 7.69231%;
    max-width: 7.69231%;
    min-width: 7.69231%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="14"] .c-slider__slide {
    flex: 0 0 7.14286%;
    max-width: 7.14286%;
    min-width: 7.14286%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="15"] .c-slider__slide {
    flex: 0 0 6.66667%;
    max-width: 6.66667%;
    min-width: 6.66667%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="16"] .c-slider__slide {
    flex: 0 0 6.25%;
    max-width: 6.25%;
    min-width: 6.25%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="17"] .c-slider__slide {
    flex: 0 0 5.88235%;
    max-width: 5.88235%;
    min-width: 5.88235%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="18"] .c-slider__slide {
    flex: 0 0 5.55556%;
    max-width: 5.55556%;
    min-width: 5.55556%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="19"] .c-slider__slide {
    flex: 0 0 5.26316%;
    max-width: 5.26316%;
    min-width: 5.26316%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="20"] .c-slider__slide {
    flex: 0 0 5%;
    max-width: 5%;
    min-width: 5%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="21"] .c-slider__slide {
    flex: 0 0 4.7619%;
    max-width: 4.7619%;
    min-width: 4.7619%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="22"] .c-slider__slide {
    flex: 0 0 4.54545%;
    max-width: 4.54545%;
    min-width: 4.54545%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="23"] .c-slider__slide {
    flex: 0 0 4.34783%;
    max-width: 4.34783%;
    min-width: 4.34783%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="24"] .c-slider__slide {
    flex: 0 0 4.16667%;
    max-width: 4.16667%;
    min-width: 4.16667%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="25"] .c-slider__slide {
    flex: 0 0 4%;
    max-width: 4%;
    min-width: 4%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="26"] .c-slider__slide {
    flex: 0 0 3.84615%;
    max-width: 3.84615%;
    min-width: 3.84615%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="27"] .c-slider__slide {
    flex: 0 0 3.7037%;
    max-width: 3.7037%;
    min-width: 3.7037%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="28"] .c-slider__slide {
    flex: 0 0 3.57143%;
    max-width: 3.57143%;
    min-width: 3.57143%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="29"] .c-slider__slide {
    flex: 0 0 3.44828%;
    max-width: 3.44828%;
    min-width: 3.44828%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="30"] .c-slider__slide {
    flex: 0 0 3.33333%;
    max-width: 3.33333%;
    min-width: 3.33333%;
    padding: 0 20px;
  }
}

@media (max-width: 63.99em) {
  .c-slider.c-slider--standard[data-slides-visible-mobile="1"] .c-slider__slide {
    padding: 0;
  }
}

.c-slider--rotator {
  position: absolute;
  height: 100%;
  width: 100%;
}

.c-slider--rotator .c-slider__slide {
  position: absolute;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  height: 50%;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  transition: 0.3s linear opacity;
}

.c-slider--rotator .c-slider__slide:nth-child(3n+1) {
  right: calc(50% + 17px);
  bottom: calc(50% + 17px);
  z-index: 1;
  align-items: flex-end;
}

.c-slider--rotator .c-slider__slide:nth-child(3n+2) {
  left: calc(50% + 17px);
  bottom: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
}

.c-slider--rotator .c-slider__slide:nth-child(3n) {
  right: calc(50% + 17px);
  top: calc(50% + 17px);
  z-index: 3;
  align-items: flex-start;
}

.c-slider--rotator .c-slider__slide.is-active {
  opacity: 1;
  pointer-events: all;
}

.c-slider--rotator .c-slider__slide img {
  max-height: 100%;
}

.c-slider--alternative {
  background: #001FA3;
  height: 100%;
  padding: 70px 0;
  position: relative;
  z-index: 1;
  overflow: visible;
}

.c-slider--alternative:before, .c-slider--alternative:after {
  content: "";
  display: block;
  position: absolute;
  top: -75px;
  left: 0;
  background: #001FA3;
  height: calc(100% + 150px);
  width: 50vw;
  z-index: -1;
}

@media (max-width: 63.99em) {
  .c-slider--alternative:before, .c-slider--alternative:after {
    width: 100vw;
    left: -30px;
  }
}

.c-slider--alternative:after {
  left: 100%;
  z-index: 1;
}

.c-slider__subtitle {
  padding-left: 70px;
  position: relative;
  z-index: 1000;
}

@media (max-width: 47.99em) {
  .c-slider-alternative__container {
    margin: 30px 0;
  }
}

.c-steps-tabs {
  color: #171717;
  padding: 3rem 0;
  background: #fff;
  position: relative;
  z-index: 2;
}

.c-steps-tabs__title {
  line-height: 1.2;
  font-size: 36px;
  font-size: 2rem;
  line-height: 1.25;
}

@media (max-width: 47.99em) {
  .c-steps-tabs__title {
    font-size: 26px;
    font-size: 1.44444rem;
    line-height: 1.19231;
  }
}

.c-steps-tabs__filter {
  padding: 16px 5px;
  font-family: "AFont", Arial;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: 1px solid #001FA3;
  color: #001FA3;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s linear;
}

.c-steps-tabs__filters {
  display: flex;
}

@media (max-width: 63.99em) {
  .c-steps-tabs__filters {
    flex-wrap: wrap;
  }
}

@media (max-width: 47.99em) {
  .c-steps-tabs__filters {
    margin: 0;
  }
}

@media (max-width: 63.99em) {
  .c-steps-tabs__filter {
    flex: 0 0 50%;
  }
}

.c-steps-tabs__filter.is-active, .c-steps-tabs__filter:hover {
  background: #001FA3;
  color: #fff;
}

@media (min-width: 64em) {
  .c-steps-tabs__filter:not(:first-child) {
    border-left: 0;
  }
}

@media (max-width: 63.99em) {
  .c-steps-tabs__filter {
    flex: 0 0 50%;
  }
}

@media (max-width: 47.99em) {
  .c-steps-tabs__filter {
    flex: 0 0 100%;
  }
}

@media (max-width: 47.99em) {
  .c-steps-tabs__container {
    flex-direction: column;
  }
}

.c-steps-tabs__step {
  border-radius: 50%;
  background-color: #6DEED8;
  color: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
  padding: 1em;
  width: 11em;
  height: 11em;
  margin: .5em 1em;
  position: relative;
  font-family: "AFont", Arial;
  font-weight: 500;
}

@media (max-width: 47.99em) {
  .c-steps-tabs__step {
    margin: 1em;
  }
}

.c-steps-tabs__step:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #6DEED8;
  opacity: .1;
  z-index: -1;
  border-radius: 50%;
}

.c-steps-tabs__step:nth-child(2n+1):before, .c-steps-tabs__step:nth-child(2n+3):before {
  transform: translate(-10%, 15%);
}

@media (max-width: 47.99em) {
  .c-steps-tabs__step:nth-child(2n+1):before, .c-steps-tabs__step:nth-child(2n+3):before {
    transform: translate(-10%, 10%);
  }
}

.c-steps-tabs__step:nth-child(2n+2):before {
  transform: translate(-10%, -15%);
}

@media (max-width: 47.99em) {
  .c-steps-tabs__step:nth-child(2n+2):before {
    transform: translate(-10%, -10%);
  }
}

.c-steps-tabs__step:nth-child(2n+4):before {
  transform: translate(10%, -15%);
}

@media (max-width: 47.99em) {
  .c-steps-tabs__step:nth-child(2n+4):before {
    transform: translate(10%, -10%);
  }
}

.c-steps-tabs__step:nth-child(2n+5):before {
  transform: translate(10%, 15%);
}

@media (max-width: 47.99em) {
  .c-steps-tabs__step:nth-child(2n+5):before {
    transform: translate(10%, 10%);
  }
}

.c-steps-tabs__step:not(:last-child):after {
  content: '';
  position: absolute;
  top: 50%;
  right: -1em;
  transform: translate(50%, -50%);
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 15px solid #FF0050;
}

@media (max-width: 47.99em) {
  .c-steps-tabs__step:not(:last-child):after {
    top: inherit;
    bottom: -1em;
    right: 50%;
    transform: translate(50%, 50%);
    border: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 15px solid #FF0050;
  }
}

.c-steps-tabs__step p {
  margin-bottom: 0;
}

.c-steps-tabs__text {
  font-family: "AFont", Arial;
  font-weight: 500;
  font-size: 16px;
}

.c-steps-tabs__table-row {
  display: flex;
  border-top: 2px solid #d8d8d8;
}

@media (max-width: 47.99em) {
  .c-steps-tabs__table-row {
    flex-direction: column;
  }
}

.c-steps-tabs__table-row:last-child {
  border-bottom: 2px solid #d8d8d8;
}

.c-steps-tabs__table-title {
  color: #001FA3;
  font-weight: 500;
  background-color: #F7F7F7;
  flex: 1;
  padding: 40px;
}

@media (max-width: 47.99em) {
  .c-steps-tabs__table-title {
    padding: 20px;
  }
}

.c-steps-tabs__table-content {
  padding: 40px;
  flex: 2;
  font-size: 0.9em;
}

@media (max-width: 47.99em) {
  .c-steps-tabs__table-content {
    flex: 1;
    padding: 20px;
  }
}

.c-steps {
  padding: 2rem 0;
}

.c-steps__item {
  text-align: center;
  padding: 1em;
}

.c-steps__item-content {
  line-height: 2em;
  font-size: .9em;
}

.c-steps__item-number {
  display: inline-block;
  margin-bottom: 1em;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  background-color: #6DEED8;
  color: #171717;
  font-size: 1.25rem;
  line-height: 2.65em;
  font-family: "AFont", Arial;
  font-weight: 500;
}

/* ==========================================================================
   #Table
   ========================================================================== */
.c-table {
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  font-family: "AFont", Arial;
  font-weight: 500;
  margin-top: 75px;
}

.c-table-section {
  padding: 75px 0 100px;
}

.c-table th {
  padding: 40px 15px;
  font-weight: 500;
  background: #F7F7F7;
  border-bottom: 2px solid #001FA3;
}

.c-table td {
  padding: 38px 15px;
  border-bottom: 1px solid #d6d6d6;
}

.c-table td:nth-child(1) {
  background: #F7F7F7;
}

.c-table td:nth-child(2) {
  font-weight: 400;
}

@media (max-width: 47.99em) {
  .c-table {
    min-width: 768px;
  }
}

@media (max-width: 47.99em) {
  .c-table__container {
    overflow-x: scroll;
  }
}

.c-tags--list {
  font-family: "AFont", Arial;
  color: #001FA3;
  font-weight: 500;
  font-size: 16px;
}

.c-tags--list .c-tags__item {
  display: flex;
  border-bottom: 1px solid #d2d2d2;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.c-tags--list .c-tags__item span {
  font-weight: 400;
  color: #171717;
}

.c-tags--list .c-tags__item:last-child {
  border: 0;
}

.c-tags--block .c-tags__item {
  padding: 8px 20px;
  min-width: 70px;
  color: #3D3D3D;
  font-family: "AFont", Arial;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
}

.c-team-member {
  margin-bottom: 1em;
}

.c-team-member__avatar {
  border-radius: 50%;
  margin-bottom: .75em;
}

.c-team-member__name {
  font-weight: 500;
  font-size: .9em;
  font-family: "AFont", Arial;
}

.c-team-member__desc {
  font-size: .75em;
  line-height: 1.5;
}

.c-team-member__desc a {
  text-decoration: underline;
}

.c-team {
  background: #E3FFFC;
  color: #171717;
  padding: 5.55rem 0;
}

.c-team__desc {
  padding: 4rem 0 1.5rem;
}

.c-team__triangle {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.c-tease {
  background: #fff;
  color: #171717;
  padding: 1.5rem 2rem;
  border: 1px solid #d2d2d2;
  margin-top: 1rem;
}

.c-tease--full {
  padding: 30px;
  height: 100%;
}

.c-tease__desc {
  padding-bottom: 50px;
  line-height: 25px;
  font-size: 14px;
  margin: 0;
  position: relative;
}

.c-tease__image {
  margin-bottom: 30px;
}

.c-testimonials-slider {
  color: #fff;
  padding: 1rem 0;
  background: #171717;
  position: relative;
  z-index: 2;
}

.c-testimonials-slider__wrap {
  margin-top: 3rem;
  position: relative;
  margin-bottom: 5rem;
}

.c-testimonials-slider__pager {
  bottom: -1rem !important;
  margin-bottom: 0;
  transform: translateY(100%);
  right: 0 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.c-testimonials-slider__pager .c-slider__page {
  margin-left: 30px;
}

@media (max-width: 63.99em) {
  .c-testimonials-slider__pager .c-slider__page {
    margin-left: 20px;
  }
}

.c-testimonials-slider__price {
  font-size: 14px;
  font-size: 0.77778rem;
  line-height: 1.42857;
}

@media (max-width: 47.99em) {
  .c-testimonials-slider__price-desc.o-flex {
    flex-wrap: wrap;
  }
}

.c-testimonials-slider__price img {
  margin-bottom: 15px;
}

@media (max-width: 47.99em) {
  .c-about .c-testimonials-slider__price {
    text-align: center;
  }
}

/* ==========================================================================
   #Text
   ========================================================================== */
.c-text {
  padding: 4.5em 0;
  position: relative;
}

.c-text__title {
  margin-bottom: 3rem;
}

.c-text__desc {
  line-height: 2em;
}

.c-text__button {
  margin-top: 2rem;
}

@media (max-width: 47.99em) {
  .c-text__content br {
    display: none;
  }
}

.c-text__gallery {
  text-align: right;
  max-height: 100px;
  position: relative;
  z-index: 1;
}

.c-text__gallery img {
  margin-bottom: 30px;
}

.c-text__sidebar--gallery {
  padding-left: 5rem !important;
}

.c-text__sidebar--picture {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5rem !important;
}

.c-text__sidebar--slider {
  position: relative;
  align-self: center;
}

.c-text__sidebar--team {
  margin-top: 4rem;
}

.c-text__anim {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: auto;
  pointer-events: none !important;
}

.c-text__timeline {
  display: flex;
  height: 290px;
  position: relative;
  counter-reset: timeline;
}

@media (max-width: 47.99em) {
  .c-text__timeline {
    flex-wrap: wrap;
    height: auto;
  }
}

.c-text__timeline:before {
  content: "";
  width: 100vw;
  height: 2px;
  background: #d2d2d2;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50vw;
  margin-top: -1px;
}

@media (max-width: 47.99em) {
  .c-text__timeline:before {
    display: none;
  }
}

.c-text__timeline-text {
  flex: 0 0 100%;
  height: 120px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}

@media (max-width: 47.99em) {
  .c-text__timeline-text {
    height: auto;
    margin: 30px 0;
  }
}

.c-text__timeline-item {
  counter-increment: timeline;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
  padding: 0 10px;
  font-size: 16px;
  font-size: 0.88889rem;
  line-height: 1.5625;
  align-self: flex-start;
}

@media (max-width: 47.99em) {
  .c-text__timeline-item {
    flex: 0 0 100%;
  }
}

.c-text__timeline-item:before {
  content: counter(timeline);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  color: #fff;
  background-color: #FF0050;
  line-height: 50px;
  font-size: 1rem;
  font-family: "AFont", Arial;
  font-weight: 500;
  margin: auto;
  order: 1;
  position: relative;
  z-index: 1;
}

@media (max-width: 47.99em) {
  .c-text__timeline-item:before {
    order: -1;
  }
}

.c-text__timeline-item:nth-child(2n) {
  align-self: flex-end;
}

.c-text__timeline-item:nth-child(2n) .c-text__timeline-text {
  order: 2;
  align-items: flex-start;
  padding-bottom: 0;
  padding-top: 10px;
}

.c-text__slider .c-slider__slide {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  min-width: 33.33%;
  text-align: left;
}

/* ==========================================================================
   #TEXTAREA
   ========================================================================== */
.c-textarea {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font: inherit;
  outline: none;
  border: 2px solid #fff;
  color: #171717;
  background: #fff;
  transition: all 0.3s linear;
  font-size: 16px;
  border-radius: 0;
  box-shadow: none !important;
  -webkit-appearance: none;
  padding: 15px;
  min-height: 135px;
  margin: 0 0 10px;
  transition: all linear 0.2s;
}

.c-textarea--disabled {
  border-style: dashed;
  pointer-events: none;
  color: #171717;
}

.c-textarea--disabled:hover {
  border-color: #fff;
}

.c-textarea::placeholder {
  color: rgba(23, 23, 23, 0.5);
}

.c-textarea:focus, .c-textarea:active {
  border-color: #6DEED8;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.wpml-ls-last-item {
  margin-left: 5px !important;
}

.wpml-ls-last-item a:before {
  content: "/";
  font-size: 10px;
  vertical-align: top;
  padding-top: 3px;
  display: inline-block;
  margin-right: 5px !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .wpml-ls-menu-item {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }
  .c-main-nav {
    text-align: center;
  }
  .wpml-ls-last-item a:before {
    color: #000 !important;
  }
}

.wpml-ls-menu-item a:after {
  display: none !important;
}

.wpml-ls-current-language a,
.wpml-ls-menu-item a:hover {
  color: #6deed8 !important;
}

.c-score__logo {
  top: 10px;
  right: 20px;
}

.c-score__desc {
  min-height: 5em;
}

.u-recapchta-info {
  padding: 15px 0;
  max-width: 300px;
  font-size: 0.6em !important;
  opacity: 0.7;
}

.grecaptcha-badge {
  visibility: hidden;
}

.u-flex-1\/5 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.u-flex-2\/5 {
  flex: 0 0 40% !important;
  max-width: 40% !important;
}

.u-flex-3\/5 {
  flex: 0 0 60% !important;
  max-width: 60% !important;
}

.u-flex-4\/5 {
  flex: 0 0 80% !important;
  max-width: 80% !important;
}

.u-flex-5\/5 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.u-flex-1\/12 {
  flex: 0 0 8.333% !important;
  max-width: 8.333% !important;
}

.u-flex-2\/12 {
  flex: 0 0 16.666% !important;
  max-width: 16.666% !important;
}

.u-flex-3\/12 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.u-flex-4\/12 {
  flex: 0 0 33.333% !important;
  max-width: 33.333% !important;
}

.u-flex-5\/12 {
  flex: 0 0 41.666% !important;
  max-width: 41.666% !important;
}

.u-flex-6\/12 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.u-flex-7\/12 {
  flex: 0 0 58.333% !important;
  max-width: 58.333% !important;
}

.u-flex-8\/12 {
  flex: 0 0 66.666% !important;
  max-width: 66.666% !important;
}

.u-flex-9\/12 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.u-flex-10\/12 {
  flex: 0 0 83.333% !important;
  max-width: 83.333% !important;
}

.u-flex-11\/12 {
  flex: 0 0 91.666% !important;
  max-width: 91.666% !important;
}

.u-flex-12\/12 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.u-flex-1\/24 {
  flex: 0 0 4.166% !important;
  max-width: 4.166% !important;
}

.u-flex-2\/24 {
  flex: 0 0 8.333% !important;
  max-width: 8.333% !important;
}

.u-flex-3\/24 {
  flex: 0 0 12.5% !important;
  max-width: 12.5% !important;
}

.u-flex-4\/24 {
  flex: 0 0 16.666% !important;
  max-width: 16.666% !important;
}

.u-flex-5\/24 {
  flex: 0 0 20.833% !important;
  max-width: 20.833% !important;
}

.u-flex-6\/24 {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.u-flex-7\/24 {
  flex: 0 0 29.166% !important;
  max-width: 29.166% !important;
}

.u-flex-8\/24 {
  flex: 0 0 33.333% !important;
  max-width: 33.333% !important;
}

.u-flex-9\/24 {
  flex: 0 0 37.5% !important;
  max-width: 37.5% !important;
}

.u-flex-10\/24 {
  flex: 0 0 41.666% !important;
  max-width: 41.666% !important;
}

.u-flex-11\/24 {
  flex: 0 0 45.833% !important;
  max-width: 45.833% !important;
}

.u-flex-12\/24 {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

.u-flex-13\/24 {
  flex: 0 0 54.166% !important;
  max-width: 54.166% !important;
}

.u-flex-14\/24 {
  flex: 0 0 58.333% !important;
  max-width: 58.333% !important;
}

.u-flex-15\/24 {
  flex: 0 0 62.5% !important;
  max-width: 62.5% !important;
}

.u-flex-16\/24 {
  flex: 0 0 66.666% !important;
  max-width: 66.666% !important;
}

.u-flex-17\/24 {
  flex: 0 0 70.833% !important;
  max-width: 70.833% !important;
}

.u-flex-18\/24 {
  flex: 0 0 75% !important;
  max-width: 75% !important;
}

.u-flex-19\/24 {
  flex: 0 0 79.166% !important;
  max-width: 79.166% !important;
}

.u-flex-20\/24 {
  flex: 0 0 83.333% !important;
  max-width: 83.333% !important;
}

.u-flex-21\/24 {
  flex: 0 0 87.5% !important;
  max-width: 87.5% !important;
}

.u-flex-22\/24 {
  flex: 0 0 91.666% !important;
  max-width: 91.666% !important;
}

.u-flex-23\/24 {
  flex: 0 0 95.833% !important;
  max-width: 95.833% !important;
}

.u-flex-24\/24 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

/*
    ==========================================================================||
    Similar to inuitcss utilities.widths, $mq-breakpoints defined allows
    you to do something like this for additional control via markup only

    e.g.
    <div class="o-flex__item u-flex-1/2@mobile"></div>
    <div class="o-flex__item u-flex-1/3@tablet u-flex-offset-1/3@tablet"></div>
    <div class="o-flex__item u-flex-1/4@desktop"></div>
    <div class="o-flex__item u-flex-1/5@wide u-flex-offset-2/5@wide"></div>
    ==========================================================================||
*/
@media (min-width: 30em) {
  .u-flex-1\/5\@small {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .u-flex-2\/5\@small {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .u-flex-3\/5\@small {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .u-flex-4\/5\@small {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .u-flex-5\/5\@small {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/12\@small {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-2\/12\@small {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-3\/12\@small {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-4\/12\@small {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-5\/12\@small {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-6\/12\@small {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-7\/12\@small {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-8\/12\@small {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-9\/12\@small {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-10\/12\@small {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-11\/12\@small {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-12\/12\@small {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/24\@small {
    flex: 0 0 4.166% !important;
    max-width: 4.166% !important;
  }
  .u-flex-2\/24\@small {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-3\/24\@small {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .u-flex-4\/24\@small {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-5\/24\@small {
    flex: 0 0 20.833% !important;
    max-width: 20.833% !important;
  }
  .u-flex-6\/24\@small {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-7\/24\@small {
    flex: 0 0 29.166% !important;
    max-width: 29.166% !important;
  }
  .u-flex-8\/24\@small {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-9\/24\@small {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .u-flex-10\/24\@small {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-11\/24\@small {
    flex: 0 0 45.833% !important;
    max-width: 45.833% !important;
  }
  .u-flex-12\/24\@small {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-13\/24\@small {
    flex: 0 0 54.166% !important;
    max-width: 54.166% !important;
  }
  .u-flex-14\/24\@small {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-15\/24\@small {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .u-flex-16\/24\@small {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-17\/24\@small {
    flex: 0 0 70.833% !important;
    max-width: 70.833% !important;
  }
  .u-flex-18\/24\@small {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-19\/24\@small {
    flex: 0 0 79.166% !important;
    max-width: 79.166% !important;
  }
  .u-flex-20\/24\@small {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-21\/24\@small {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .u-flex-22\/24\@small {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-23\/24\@small {
    flex: 0 0 95.833% !important;
    max-width: 95.833% !important;
  }
  .u-flex-24\/24\@small {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 48em) {
  .u-flex-1\/5\@medium {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .u-flex-2\/5\@medium {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .u-flex-3\/5\@medium {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .u-flex-4\/5\@medium {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .u-flex-5\/5\@medium {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/12\@medium {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-2\/12\@medium {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-3\/12\@medium {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-4\/12\@medium {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-5\/12\@medium {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-6\/12\@medium {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-7\/12\@medium {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-8\/12\@medium {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-9\/12\@medium {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-10\/12\@medium {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-11\/12\@medium {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-12\/12\@medium {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/24\@medium {
    flex: 0 0 4.166% !important;
    max-width: 4.166% !important;
  }
  .u-flex-2\/24\@medium {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-3\/24\@medium {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .u-flex-4\/24\@medium {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-5\/24\@medium {
    flex: 0 0 20.833% !important;
    max-width: 20.833% !important;
  }
  .u-flex-6\/24\@medium {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-7\/24\@medium {
    flex: 0 0 29.166% !important;
    max-width: 29.166% !important;
  }
  .u-flex-8\/24\@medium {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-9\/24\@medium {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .u-flex-10\/24\@medium {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-11\/24\@medium {
    flex: 0 0 45.833% !important;
    max-width: 45.833% !important;
  }
  .u-flex-12\/24\@medium {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-13\/24\@medium {
    flex: 0 0 54.166% !important;
    max-width: 54.166% !important;
  }
  .u-flex-14\/24\@medium {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-15\/24\@medium {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .u-flex-16\/24\@medium {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-17\/24\@medium {
    flex: 0 0 70.833% !important;
    max-width: 70.833% !important;
  }
  .u-flex-18\/24\@medium {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-19\/24\@medium {
    flex: 0 0 79.166% !important;
    max-width: 79.166% !important;
  }
  .u-flex-20\/24\@medium {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-21\/24\@medium {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .u-flex-22\/24\@medium {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-23\/24\@medium {
    flex: 0 0 95.833% !important;
    max-width: 95.833% !important;
  }
  .u-flex-24\/24\@medium {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 64em) {
  .u-flex-1\/5\@large {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .u-flex-2\/5\@large {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .u-flex-3\/5\@large {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .u-flex-4\/5\@large {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .u-flex-5\/5\@large {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/12\@large {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-2\/12\@large {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-3\/12\@large {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-4\/12\@large {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-5\/12\@large {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-6\/12\@large {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-7\/12\@large {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-8\/12\@large {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-9\/12\@large {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-10\/12\@large {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-11\/12\@large {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-12\/12\@large {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/24\@large {
    flex: 0 0 4.166% !important;
    max-width: 4.166% !important;
  }
  .u-flex-2\/24\@large {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-3\/24\@large {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .u-flex-4\/24\@large {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-5\/24\@large {
    flex: 0 0 20.833% !important;
    max-width: 20.833% !important;
  }
  .u-flex-6\/24\@large {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-7\/24\@large {
    flex: 0 0 29.166% !important;
    max-width: 29.166% !important;
  }
  .u-flex-8\/24\@large {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-9\/24\@large {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .u-flex-10\/24\@large {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-11\/24\@large {
    flex: 0 0 45.833% !important;
    max-width: 45.833% !important;
  }
  .u-flex-12\/24\@large {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-13\/24\@large {
    flex: 0 0 54.166% !important;
    max-width: 54.166% !important;
  }
  .u-flex-14\/24\@large {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-15\/24\@large {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .u-flex-16\/24\@large {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-17\/24\@large {
    flex: 0 0 70.833% !important;
    max-width: 70.833% !important;
  }
  .u-flex-18\/24\@large {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-19\/24\@large {
    flex: 0 0 79.166% !important;
    max-width: 79.166% !important;
  }
  .u-flex-20\/24\@large {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-21\/24\@large {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .u-flex-22\/24\@large {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-23\/24\@large {
    flex: 0 0 95.833% !important;
    max-width: 95.833% !important;
  }
  .u-flex-24\/24\@large {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 80em) {
  .u-flex-1\/5\@xlarge {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .u-flex-2\/5\@xlarge {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .u-flex-3\/5\@xlarge {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .u-flex-4\/5\@xlarge {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .u-flex-5\/5\@xlarge {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/12\@xlarge {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-2\/12\@xlarge {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-3\/12\@xlarge {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-4\/12\@xlarge {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-5\/12\@xlarge {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-6\/12\@xlarge {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-7\/12\@xlarge {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-8\/12\@xlarge {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-9\/12\@xlarge {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-10\/12\@xlarge {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-11\/12\@xlarge {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-12\/12\@xlarge {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/24\@xlarge {
    flex: 0 0 4.166% !important;
    max-width: 4.166% !important;
  }
  .u-flex-2\/24\@xlarge {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-3\/24\@xlarge {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .u-flex-4\/24\@xlarge {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-5\/24\@xlarge {
    flex: 0 0 20.833% !important;
    max-width: 20.833% !important;
  }
  .u-flex-6\/24\@xlarge {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-7\/24\@xlarge {
    flex: 0 0 29.166% !important;
    max-width: 29.166% !important;
  }
  .u-flex-8\/24\@xlarge {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-9\/24\@xlarge {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .u-flex-10\/24\@xlarge {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-11\/24\@xlarge {
    flex: 0 0 45.833% !important;
    max-width: 45.833% !important;
  }
  .u-flex-12\/24\@xlarge {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-13\/24\@xlarge {
    flex: 0 0 54.166% !important;
    max-width: 54.166% !important;
  }
  .u-flex-14\/24\@xlarge {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-15\/24\@xlarge {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .u-flex-16\/24\@xlarge {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-17\/24\@xlarge {
    flex: 0 0 70.833% !important;
    max-width: 70.833% !important;
  }
  .u-flex-18\/24\@xlarge {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-19\/24\@xlarge {
    flex: 0 0 79.166% !important;
    max-width: 79.166% !important;
  }
  .u-flex-20\/24\@xlarge {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-21\/24\@xlarge {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .u-flex-22\/24\@xlarge {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-23\/24\@xlarge {
    flex: 0 0 95.833% !important;
    max-width: 95.833% !important;
  }
  .u-flex-24\/24\@xlarge {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 85.375em) {
  .u-flex-1\/5\@xxlarge {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .u-flex-2\/5\@xxlarge {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .u-flex-3\/5\@xxlarge {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .u-flex-4\/5\@xxlarge {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .u-flex-5\/5\@xxlarge {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/12\@xxlarge {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-2\/12\@xxlarge {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-3\/12\@xxlarge {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-4\/12\@xxlarge {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-5\/12\@xxlarge {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-6\/12\@xxlarge {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-7\/12\@xxlarge {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-8\/12\@xxlarge {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-9\/12\@xxlarge {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-10\/12\@xxlarge {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-11\/12\@xxlarge {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-12\/12\@xxlarge {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/24\@xxlarge {
    flex: 0 0 4.166% !important;
    max-width: 4.166% !important;
  }
  .u-flex-2\/24\@xxlarge {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-3\/24\@xxlarge {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .u-flex-4\/24\@xxlarge {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-5\/24\@xxlarge {
    flex: 0 0 20.833% !important;
    max-width: 20.833% !important;
  }
  .u-flex-6\/24\@xxlarge {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-7\/24\@xxlarge {
    flex: 0 0 29.166% !important;
    max-width: 29.166% !important;
  }
  .u-flex-8\/24\@xxlarge {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-9\/24\@xxlarge {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .u-flex-10\/24\@xxlarge {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-11\/24\@xxlarge {
    flex: 0 0 45.833% !important;
    max-width: 45.833% !important;
  }
  .u-flex-12\/24\@xxlarge {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-13\/24\@xxlarge {
    flex: 0 0 54.166% !important;
    max-width: 54.166% !important;
  }
  .u-flex-14\/24\@xxlarge {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-15\/24\@xxlarge {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .u-flex-16\/24\@xxlarge {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-17\/24\@xxlarge {
    flex: 0 0 70.833% !important;
    max-width: 70.833% !important;
  }
  .u-flex-18\/24\@xxlarge {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-19\/24\@xxlarge {
    flex: 0 0 79.166% !important;
    max-width: 79.166% !important;
  }
  .u-flex-20\/24\@xxlarge {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-21\/24\@xxlarge {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .u-flex-22\/24\@xxlarge {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-23\/24\@xxlarge {
    flex: 0 0 95.833% !important;
    max-width: 95.833% !important;
  }
  .u-flex-24\/24\@xxlarge {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 100em) {
  .u-flex-1\/5\@full {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .u-flex-2\/5\@full {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .u-flex-3\/5\@full {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .u-flex-4\/5\@full {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .u-flex-5\/5\@full {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/12\@full {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-2\/12\@full {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-3\/12\@full {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-4\/12\@full {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-5\/12\@full {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-6\/12\@full {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-7\/12\@full {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-8\/12\@full {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-9\/12\@full {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-10\/12\@full {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-11\/12\@full {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-12\/12\@full {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/24\@full {
    flex: 0 0 4.166% !important;
    max-width: 4.166% !important;
  }
  .u-flex-2\/24\@full {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-3\/24\@full {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .u-flex-4\/24\@full {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-5\/24\@full {
    flex: 0 0 20.833% !important;
    max-width: 20.833% !important;
  }
  .u-flex-6\/24\@full {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-7\/24\@full {
    flex: 0 0 29.166% !important;
    max-width: 29.166% !important;
  }
  .u-flex-8\/24\@full {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-9\/24\@full {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .u-flex-10\/24\@full {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-11\/24\@full {
    flex: 0 0 45.833% !important;
    max-width: 45.833% !important;
  }
  .u-flex-12\/24\@full {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-13\/24\@full {
    flex: 0 0 54.166% !important;
    max-width: 54.166% !important;
  }
  .u-flex-14\/24\@full {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-15\/24\@full {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .u-flex-16\/24\@full {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-17\/24\@full {
    flex: 0 0 70.833% !important;
    max-width: 70.833% !important;
  }
  .u-flex-18\/24\@full {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-19\/24\@full {
    flex: 0 0 79.166% !important;
    max-width: 79.166% !important;
  }
  .u-flex-20\/24\@full {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-21\/24\@full {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .u-flex-22\/24\@full {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-23\/24\@full {
    flex: 0 0 95.833% !important;
    max-width: 95.833% !important;
  }
  .u-flex-24\/24\@full {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 120em) {
  .u-flex-1\/5\@xfull {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .u-flex-2\/5\@xfull {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .u-flex-3\/5\@xfull {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .u-flex-4\/5\@xfull {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .u-flex-5\/5\@xfull {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/12\@xfull {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-2\/12\@xfull {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-3\/12\@xfull {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-4\/12\@xfull {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-5\/12\@xfull {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-6\/12\@xfull {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-7\/12\@xfull {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-8\/12\@xfull {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-9\/12\@xfull {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-10\/12\@xfull {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-11\/12\@xfull {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-12\/12\@xfull {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .u-flex-1\/24\@xfull {
    flex: 0 0 4.166% !important;
    max-width: 4.166% !important;
  }
  .u-flex-2\/24\@xfull {
    flex: 0 0 8.333% !important;
    max-width: 8.333% !important;
  }
  .u-flex-3\/24\@xfull {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
  }
  .u-flex-4\/24\@xfull {
    flex: 0 0 16.666% !important;
    max-width: 16.666% !important;
  }
  .u-flex-5\/24\@xfull {
    flex: 0 0 20.833% !important;
    max-width: 20.833% !important;
  }
  .u-flex-6\/24\@xfull {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
  .u-flex-7\/24\@xfull {
    flex: 0 0 29.166% !important;
    max-width: 29.166% !important;
  }
  .u-flex-8\/24\@xfull {
    flex: 0 0 33.333% !important;
    max-width: 33.333% !important;
  }
  .u-flex-9\/24\@xfull {
    flex: 0 0 37.5% !important;
    max-width: 37.5% !important;
  }
  .u-flex-10\/24\@xfull {
    flex: 0 0 41.666% !important;
    max-width: 41.666% !important;
  }
  .u-flex-11\/24\@xfull {
    flex: 0 0 45.833% !important;
    max-width: 45.833% !important;
  }
  .u-flex-12\/24\@xfull {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .u-flex-13\/24\@xfull {
    flex: 0 0 54.166% !important;
    max-width: 54.166% !important;
  }
  .u-flex-14\/24\@xfull {
    flex: 0 0 58.333% !important;
    max-width: 58.333% !important;
  }
  .u-flex-15\/24\@xfull {
    flex: 0 0 62.5% !important;
    max-width: 62.5% !important;
  }
  .u-flex-16\/24\@xfull {
    flex: 0 0 66.666% !important;
    max-width: 66.666% !important;
  }
  .u-flex-17\/24\@xfull {
    flex: 0 0 70.833% !important;
    max-width: 70.833% !important;
  }
  .u-flex-18\/24\@xfull {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .u-flex-19\/24\@xfull {
    flex: 0 0 79.166% !important;
    max-width: 79.166% !important;
  }
  .u-flex-20\/24\@xfull {
    flex: 0 0 83.333% !important;
    max-width: 83.333% !important;
  }
  .u-flex-21\/24\@xfull {
    flex: 0 0 87.5% !important;
    max-width: 87.5% !important;
  }
  .u-flex-22\/24\@xfull {
    flex: 0 0 91.666% !important;
    max-width: 91.666% !important;
  }
  .u-flex-23\/24\@xfull {
    flex: 0 0 95.833% !important;
    max-width: 95.833% !important;
  }
  .u-flex-24\/24\@xfull {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.u-hidden-visually-2 {
  opacity: 0;
  pointer-events: none;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

@media (min-width: 30em) {
  .u-hidden\@small {
    display: none !important;
  }
}

@media (max-width: 29.99em) {
  .u-hidden\@less-then-small {
    display: none !important;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .u-hidden\@small-till-medium {
    display: none !important;
  }
}

@media (min-width: 30em) and (max-width: 63.99em) {
  .u-hidden\@small-till-large {
    display: none !important;
  }
}

@media (min-width: 30em) and (max-width: 79.99em) {
  .u-hidden\@small-till-xlarge {
    display: none !important;
  }
}

@media (min-width: 30em) and (max-width: 85.365em) {
  .u-hidden\@small-till-xxlarge {
    display: none !important;
  }
}

@media (min-width: 30em) and (max-width: 99.99em) {
  .u-hidden\@small-till-full {
    display: none !important;
  }
}

@media (min-width: 30em) and (max-width: 119.99em) {
  .u-hidden\@small-till-xfull {
    display: none !important;
  }
}

@media (min-width: 48em) {
  .u-hidden\@medium {
    display: none !important;
  }
}

@media (max-width: 47.99em) {
  .u-hidden\@less-then-medium {
    display: none !important;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .u-hidden\@medium-till-large {
    display: none !important;
  }
}

@media (min-width: 48em) and (max-width: 79.99em) {
  .u-hidden\@medium-till-xlarge {
    display: none !important;
  }
}

@media (min-width: 48em) and (max-width: 85.365em) {
  .u-hidden\@medium-till-xxlarge {
    display: none !important;
  }
}

@media (min-width: 48em) and (max-width: 99.99em) {
  .u-hidden\@medium-till-full {
    display: none !important;
  }
}

@media (min-width: 48em) and (max-width: 119.99em) {
  .u-hidden\@medium-till-xfull {
    display: none !important;
  }
}

@media (min-width: 64em) {
  .u-hidden\@large {
    display: none !important;
  }
}

@media (max-width: 63.99em) {
  .u-hidden\@less-then-large {
    display: none !important;
  }
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .u-hidden\@large-till-xlarge {
    display: none !important;
  }
}

@media (min-width: 64em) and (max-width: 85.365em) {
  .u-hidden\@large-till-xxlarge {
    display: none !important;
  }
}

@media (min-width: 64em) and (max-width: 99.99em) {
  .u-hidden\@large-till-full {
    display: none !important;
  }
}

@media (min-width: 64em) and (max-width: 119.99em) {
  .u-hidden\@large-till-xfull {
    display: none !important;
  }
}

@media (min-width: 80em) {
  .u-hidden\@xlarge {
    display: none !important;
  }
}

@media (max-width: 79.99em) {
  .u-hidden\@less-then-xlarge {
    display: none !important;
  }
}

@media (min-width: 80em) and (max-width: 85.365em) {
  .u-hidden\@xlarge-till-xxlarge {
    display: none !important;
  }
}

@media (min-width: 80em) and (max-width: 99.99em) {
  .u-hidden\@xlarge-till-full {
    display: none !important;
  }
}

@media (min-width: 80em) and (max-width: 119.99em) {
  .u-hidden\@xlarge-till-xfull {
    display: none !important;
  }
}

@media (min-width: 85.375em) {
  .u-hidden\@xxlarge {
    display: none !important;
  }
}

@media (max-width: 85.365em) {
  .u-hidden\@less-then-xxlarge {
    display: none !important;
  }
}

@media (min-width: 85.375em) and (max-width: 99.99em) {
  .u-hidden\@xxlarge-till-full {
    display: none !important;
  }
}

@media (min-width: 85.375em) and (max-width: 119.99em) {
  .u-hidden\@xxlarge-till-xfull {
    display: none !important;
  }
}

@media (min-width: 100em) {
  .u-hidden\@full {
    display: none !important;
  }
}

@media (max-width: 99.99em) {
  .u-hidden\@less-then-full {
    display: none !important;
  }
}

@media (min-width: 100em) and (max-width: 119.99em) {
  .u-hidden\@full-till-xfull {
    display: none !important;
  }
}

@media (min-width: 120em) {
  .u-hidden\@xfull {
    display: none !important;
  }
}

@media (max-width: 119.99em) {
  .u-hidden\@less-then-xfull {
    display: none !important;
  }
}

/* 
    ==========================================================================
    #TEXT UTILITIES
    ==========================================================================
*/
/* 
    Size utilities
*/
.u-text--bigger {
  font-size: 20px !important;
  font-size: 1.11111rem !important;
  line-height: 1.25 !important;
}

.u-text--natural {
  font-size: 18px !important;
  font-size: 1rem !important;
  line-height: 1.38889 !important;
}

.u-text--smaller {
  font-size: 14px !important;
  font-size: 0.77778rem !important;
  line-height: 1.42857 !important;
}

.u-text--smallest {
  font-size: 12px !important;
  font-size: 0.66667rem !important;
  line-height: 1.66667 !important;
}

.has-normal-font-size {
  font-size: 18px !important;
  font-size: 1rem !important;
}

.has-regular-font-size {
  font-size: 18px !important;
  font-size: 1rem !important;
}

.has-small-font-size {
  font-size: 16px !important;
  font-size: 0.88889rem !important;
}

.has-large-font-size {
  font-size: 24px !important;
  font-size: 1.33333rem !important;
}

.has-larger-font-size {
  font-size: 30px !important;
  font-size: 1.66667rem !important;
  line-height: 2.5rem;
}

/* 
    Weight utilities
*/
.u-text--strong {
  font-weight: 700 !important;
}

.u-text--regular {
  font-weight: 400 !important;
}

/* 
    Transform utilities
*/
.u-text--uppercase {
  text-transform: uppercase;
}

.u-text--lowercase {
  text-transform: lowercase;
}

/*
    Base Colors utilities
*/
.u-color--main-1 {
  background-color: #001FA3 !important;
}

.u-color--sub-1 {
  background-color: #6DEED8 !important;
}

.u-color--sub-2 {
  background-color: #FF0050 !important;
}

.u-color--sub-3 {
  background-color: #E3FFFC !important;
}

.u-color--func-1 {
  background-color: #fff !important;
}

.u-color--func-2 {
  background-color: #F7F7F7 !important;
}

.u-color--func-3 {
  background-color: #171717 !important;
}

/*
    Colors utilities
*/
.u-font-color--1 {
  color: #fff !important;
}

.u-font-color--2 {
  color: #171717 !important;
}

.u-font-color--3 {
  color: #001FA3 !important;
}

.u-font-color--4 {
  color: #6DEED8 !important;
}

.u-font-color--5 {
  color: #FF0050 !important;
}

.u-bg-color--1 {
  background-color: #001FA3 !important;
}

.u-bg-color--2 {
  background-color: #fff !important;
}

.u-bg-color--3 {
  background-color: #171717 !important;
}

.u-bg-color--4 {
  background-color: #6DEED8 !important;
}

.u-bg-color--5 {
  background-color: #FF0050 !important;
}

.u-bg-color--6 {
  background-color: #E3FFFC !important;
}

.u-bg-color--7 {
  background-color: #F7F7F7 !important;
}

.u-border-color--1 {
  border-color: #6DEED8 !important;
}

.u-border-color--2 {
  border-color: #001FA3 !important;
}

.u-border-color--3 {
  border-color: #FF0050 !important;
}

.u-border-color--4 {
  border-color: #171717 !important;
}

.u-border-color--5 {
  border-color: #fff !important;
}

.u-text--grayscale {
  color: rgba(23, 23, 23, 0.5) !important;
}

.u-text--colored {
  color: #FF0050 !important;
}

/* 
    Align utilities
*/
.u-text--left {
  text-align: left !important;
}

@media (min-width: 30em) {
  .u-text--left\@small {
    text-align: left !important;
  }
}

@media (max-width: 29.99em) {
  .u-text--left\@less-then-small {
    text-align: left !important;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .u-text--left\@small-till-medium {
    text-align: left !important;
  }
}

@media (min-width: 30em) and (max-width: 63.99em) {
  .u-text--left\@small-till-large {
    text-align: left !important;
  }
}

@media (min-width: 30em) and (max-width: 79.99em) {
  .u-text--left\@small-till-xlarge {
    text-align: left !important;
  }
}

@media (min-width: 30em) and (max-width: 85.365em) {
  .u-text--left\@small-till-xxlarge {
    text-align: left !important;
  }
}

@media (min-width: 30em) and (max-width: 99.99em) {
  .u-text--left\@small-till-full {
    text-align: left !important;
  }
}

@media (min-width: 30em) and (max-width: 119.99em) {
  .u-text--left\@small-till-xfull {
    text-align: left !important;
  }
}

@media (min-width: 48em) {
  .u-text--left\@medium {
    text-align: left !important;
  }
}

@media (max-width: 47.99em) {
  .u-text--left\@less-then-medium {
    text-align: left !important;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .u-text--left\@medium-till-large {
    text-align: left !important;
  }
}

@media (min-width: 48em) and (max-width: 79.99em) {
  .u-text--left\@medium-till-xlarge {
    text-align: left !important;
  }
}

@media (min-width: 48em) and (max-width: 85.365em) {
  .u-text--left\@medium-till-xxlarge {
    text-align: left !important;
  }
}

@media (min-width: 48em) and (max-width: 99.99em) {
  .u-text--left\@medium-till-full {
    text-align: left !important;
  }
}

@media (min-width: 48em) and (max-width: 119.99em) {
  .u-text--left\@medium-till-xfull {
    text-align: left !important;
  }
}

@media (min-width: 64em) {
  .u-text--left\@large {
    text-align: left !important;
  }
}

@media (max-width: 63.99em) {
  .u-text--left\@less-then-large {
    text-align: left !important;
  }
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .u-text--left\@large-till-xlarge {
    text-align: left !important;
  }
}

@media (min-width: 64em) and (max-width: 85.365em) {
  .u-text--left\@large-till-xxlarge {
    text-align: left !important;
  }
}

@media (min-width: 64em) and (max-width: 99.99em) {
  .u-text--left\@large-till-full {
    text-align: left !important;
  }
}

@media (min-width: 64em) and (max-width: 119.99em) {
  .u-text--left\@large-till-xfull {
    text-align: left !important;
  }
}

@media (min-width: 80em) {
  .u-text--left\@xlarge {
    text-align: left !important;
  }
}

@media (max-width: 79.99em) {
  .u-text--left\@less-then-xlarge {
    text-align: left !important;
  }
}

@media (min-width: 80em) and (max-width: 85.365em) {
  .u-text--left\@xlarge-till-xxlarge {
    text-align: left !important;
  }
}

@media (min-width: 80em) and (max-width: 99.99em) {
  .u-text--left\@xlarge-till-full {
    text-align: left !important;
  }
}

@media (min-width: 80em) and (max-width: 119.99em) {
  .u-text--left\@xlarge-till-xfull {
    text-align: left !important;
  }
}

@media (min-width: 85.375em) {
  .u-text--left\@xxlarge {
    text-align: left !important;
  }
}

@media (max-width: 85.365em) {
  .u-text--left\@less-then-xxlarge {
    text-align: left !important;
  }
}

@media (min-width: 85.375em) and (max-width: 99.99em) {
  .u-text--left\@xxlarge-till-full {
    text-align: left !important;
  }
}

@media (min-width: 85.375em) and (max-width: 119.99em) {
  .u-text--left\@xxlarge-till-xfull {
    text-align: left !important;
  }
}

@media (min-width: 100em) {
  .u-text--left\@full {
    text-align: left !important;
  }
}

@media (max-width: 99.99em) {
  .u-text--left\@less-then-full {
    text-align: left !important;
  }
}

@media (min-width: 100em) and (max-width: 119.99em) {
  .u-text--left\@full-till-xfull {
    text-align: left !important;
  }
}

@media (min-width: 120em) {
  .u-text--left\@xfull {
    text-align: left !important;
  }
}

@media (max-width: 119.99em) {
  .u-text--left\@less-then-xfull {
    text-align: left !important;
  }
}

.u-text--center {
  text-align: center !important;
}

@media (min-width: 30em) {
  .u-text--center\@small {
    text-align: center !important;
  }
}

@media (max-width: 29.99em) {
  .u-text--center\@less-then-small {
    text-align: center !important;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .u-text--center\@small-till-medium {
    text-align: center !important;
  }
}

@media (min-width: 30em) and (max-width: 63.99em) {
  .u-text--center\@small-till-large {
    text-align: center !important;
  }
}

@media (min-width: 30em) and (max-width: 79.99em) {
  .u-text--center\@small-till-xlarge {
    text-align: center !important;
  }
}

@media (min-width: 30em) and (max-width: 85.365em) {
  .u-text--center\@small-till-xxlarge {
    text-align: center !important;
  }
}

@media (min-width: 30em) and (max-width: 99.99em) {
  .u-text--center\@small-till-full {
    text-align: center !important;
  }
}

@media (min-width: 30em) and (max-width: 119.99em) {
  .u-text--center\@small-till-xfull {
    text-align: center !important;
  }
}

@media (min-width: 48em) {
  .u-text--center\@medium {
    text-align: center !important;
  }
}

@media (max-width: 47.99em) {
  .u-text--center\@less-then-medium {
    text-align: center !important;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .u-text--center\@medium-till-large {
    text-align: center !important;
  }
}

@media (min-width: 48em) and (max-width: 79.99em) {
  .u-text--center\@medium-till-xlarge {
    text-align: center !important;
  }
}

@media (min-width: 48em) and (max-width: 85.365em) {
  .u-text--center\@medium-till-xxlarge {
    text-align: center !important;
  }
}

@media (min-width: 48em) and (max-width: 99.99em) {
  .u-text--center\@medium-till-full {
    text-align: center !important;
  }
}

@media (min-width: 48em) and (max-width: 119.99em) {
  .u-text--center\@medium-till-xfull {
    text-align: center !important;
  }
}

@media (min-width: 64em) {
  .u-text--center\@large {
    text-align: center !important;
  }
}

@media (max-width: 63.99em) {
  .u-text--center\@less-then-large {
    text-align: center !important;
  }
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .u-text--center\@large-till-xlarge {
    text-align: center !important;
  }
}

@media (min-width: 64em) and (max-width: 85.365em) {
  .u-text--center\@large-till-xxlarge {
    text-align: center !important;
  }
}

@media (min-width: 64em) and (max-width: 99.99em) {
  .u-text--center\@large-till-full {
    text-align: center !important;
  }
}

@media (min-width: 64em) and (max-width: 119.99em) {
  .u-text--center\@large-till-xfull {
    text-align: center !important;
  }
}

@media (min-width: 80em) {
  .u-text--center\@xlarge {
    text-align: center !important;
  }
}

@media (max-width: 79.99em) {
  .u-text--center\@less-then-xlarge {
    text-align: center !important;
  }
}

@media (min-width: 80em) and (max-width: 85.365em) {
  .u-text--center\@xlarge-till-xxlarge {
    text-align: center !important;
  }
}

@media (min-width: 80em) and (max-width: 99.99em) {
  .u-text--center\@xlarge-till-full {
    text-align: center !important;
  }
}

@media (min-width: 80em) and (max-width: 119.99em) {
  .u-text--center\@xlarge-till-xfull {
    text-align: center !important;
  }
}

@media (min-width: 85.375em) {
  .u-text--center\@xxlarge {
    text-align: center !important;
  }
}

@media (max-width: 85.365em) {
  .u-text--center\@less-then-xxlarge {
    text-align: center !important;
  }
}

@media (min-width: 85.375em) and (max-width: 99.99em) {
  .u-text--center\@xxlarge-till-full {
    text-align: center !important;
  }
}

@media (min-width: 85.375em) and (max-width: 119.99em) {
  .u-text--center\@xxlarge-till-xfull {
    text-align: center !important;
  }
}

@media (min-width: 100em) {
  .u-text--center\@full {
    text-align: center !important;
  }
}

@media (max-width: 99.99em) {
  .u-text--center\@less-then-full {
    text-align: center !important;
  }
}

@media (min-width: 100em) and (max-width: 119.99em) {
  .u-text--center\@full-till-xfull {
    text-align: center !important;
  }
}

@media (min-width: 120em) {
  .u-text--center\@xfull {
    text-align: center !important;
  }
}

@media (max-width: 119.99em) {
  .u-text--center\@less-then-xfull {
    text-align: center !important;
  }
}

.u-text--right {
  text-align: right !important;
}

@media (min-width: 30em) {
  .u-text--right\@small {
    text-align: right !important;
  }
}

@media (max-width: 29.99em) {
  .u-text--right\@less-then-small {
    text-align: right !important;
  }
}

@media (min-width: 30em) and (max-width: 47.99em) {
  .u-text--right\@small-till-medium {
    text-align: right !important;
  }
}

@media (min-width: 30em) and (max-width: 63.99em) {
  .u-text--right\@small-till-large {
    text-align: right !important;
  }
}

@media (min-width: 30em) and (max-width: 79.99em) {
  .u-text--right\@small-till-xlarge {
    text-align: right !important;
  }
}

@media (min-width: 30em) and (max-width: 85.365em) {
  .u-text--right\@small-till-xxlarge {
    text-align: right !important;
  }
}

@media (min-width: 30em) and (max-width: 99.99em) {
  .u-text--right\@small-till-full {
    text-align: right !important;
  }
}

@media (min-width: 30em) and (max-width: 119.99em) {
  .u-text--right\@small-till-xfull {
    text-align: right !important;
  }
}

@media (min-width: 48em) {
  .u-text--right\@medium {
    text-align: right !important;
  }
}

@media (max-width: 47.99em) {
  .u-text--right\@less-then-medium {
    text-align: right !important;
  }
}

@media (min-width: 48em) and (max-width: 63.99em) {
  .u-text--right\@medium-till-large {
    text-align: right !important;
  }
}

@media (min-width: 48em) and (max-width: 79.99em) {
  .u-text--right\@medium-till-xlarge {
    text-align: right !important;
  }
}

@media (min-width: 48em) and (max-width: 85.365em) {
  .u-text--right\@medium-till-xxlarge {
    text-align: right !important;
  }
}

@media (min-width: 48em) and (max-width: 99.99em) {
  .u-text--right\@medium-till-full {
    text-align: right !important;
  }
}

@media (min-width: 48em) and (max-width: 119.99em) {
  .u-text--right\@medium-till-xfull {
    text-align: right !important;
  }
}

@media (min-width: 64em) {
  .u-text--right\@large {
    text-align: right !important;
  }
}

@media (max-width: 63.99em) {
  .u-text--right\@less-then-large {
    text-align: right !important;
  }
}

@media (min-width: 64em) and (max-width: 79.99em) {
  .u-text--right\@large-till-xlarge {
    text-align: right !important;
  }
}

@media (min-width: 64em) and (max-width: 85.365em) {
  .u-text--right\@large-till-xxlarge {
    text-align: right !important;
  }
}

@media (min-width: 64em) and (max-width: 99.99em) {
  .u-text--right\@large-till-full {
    text-align: right !important;
  }
}

@media (min-width: 64em) and (max-width: 119.99em) {
  .u-text--right\@large-till-xfull {
    text-align: right !important;
  }
}

@media (min-width: 80em) {
  .u-text--right\@xlarge {
    text-align: right !important;
  }
}

@media (max-width: 79.99em) {
  .u-text--right\@less-then-xlarge {
    text-align: right !important;
  }
}

@media (min-width: 80em) and (max-width: 85.365em) {
  .u-text--right\@xlarge-till-xxlarge {
    text-align: right !important;
  }
}

@media (min-width: 80em) and (max-width: 99.99em) {
  .u-text--right\@xlarge-till-full {
    text-align: right !important;
  }
}

@media (min-width: 80em) and (max-width: 119.99em) {
  .u-text--right\@xlarge-till-xfull {
    text-align: right !important;
  }
}

@media (min-width: 85.375em) {
  .u-text--right\@xxlarge {
    text-align: right !important;
  }
}

@media (max-width: 85.365em) {
  .u-text--right\@less-then-xxlarge {
    text-align: right !important;
  }
}

@media (min-width: 85.375em) and (max-width: 99.99em) {
  .u-text--right\@xxlarge-till-full {
    text-align: right !important;
  }
}

@media (min-width: 85.375em) and (max-width: 119.99em) {
  .u-text--right\@xxlarge-till-xfull {
    text-align: right !important;
  }
}

@media (min-width: 100em) {
  .u-text--right\@full {
    text-align: right !important;
  }
}

@media (max-width: 99.99em) {
  .u-text--right\@less-then-full {
    text-align: right !important;
  }
}

@media (min-width: 100em) and (max-width: 119.99em) {
  .u-text--right\@full-till-xfull {
    text-align: right !important;
  }
}

@media (min-width: 120em) {
  .u-text--right\@xfull {
    text-align: right !important;
  }
}

@media (max-width: 119.99em) {
  .u-text--right\@less-then-xfull {
    text-align: right !important;
  }
}

.u-text--intend {
  padding-left: 30px !important;
}

/*

    State utilities

*/
.u-disabled {
  cursor: not-allowed !important;
}

/* ==========================================================================
   #SPACINGS
   ========================================================================== */
/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 *   .u-padding-horizontal {}
 *   .u-padding-vertical-small {}
 */
.u-padding {
  padding: 15px !important;
}

.u-padding-tiny {
  padding: 5px !important;
}

.u-padding-small {
  padding: 10px !important;
}

.u-padding-big {
  padding: 20px !important;
}

.u-padding-large {
  padding: 40px !important;
}

.u-padding-huge {
  padding: 80px !important;
}

.u-padding-none {
  padding: 0 !important;
}

.u-padding-top {
  padding-top: 15px !important;
}

.u-padding-top-tiny {
  padding-top: 5px !important;
}

.u-padding-top-small {
  padding-top: 10px !important;
}

.u-padding-top-big {
  padding-top: 20px !important;
}

.u-padding-top-large {
  padding-top: 40px !important;
}

.u-padding-top-huge {
  padding-top: 80px !important;
}

.u-padding-top-none {
  padding-top: 0 !important;
}

.u-padding-right {
  padding-right: 15px !important;
}

.u-padding-right-tiny {
  padding-right: 5px !important;
}

.u-padding-right-small {
  padding-right: 10px !important;
}

.u-padding-right-big {
  padding-right: 20px !important;
}

.u-padding-right-large {
  padding-right: 40px !important;
}

.u-padding-right-huge {
  padding-right: 80px !important;
}

.u-padding-right-none {
  padding-right: 0 !important;
}

.u-padding-bottom {
  padding-bottom: 15px !important;
}

.u-padding-bottom-tiny {
  padding-bottom: 5px !important;
}

.u-padding-bottom-small {
  padding-bottom: 10px !important;
}

.u-padding-bottom-big {
  padding-bottom: 20px !important;
}

.u-padding-bottom-large {
  padding-bottom: 40px !important;
}

.u-padding-bottom-huge {
  padding-bottom: 80px !important;
}

.u-padding-bottom-none {
  padding-bottom: 0 !important;
}

.u-padding-left {
  padding-left: 15px !important;
}

.u-padding-left-tiny {
  padding-left: 5px !important;
}

.u-padding-left-small {
  padding-left: 10px !important;
}

.u-padding-left-big {
  padding-left: 20px !important;
}

.u-padding-left-large {
  padding-left: 40px !important;
}

.u-padding-left-huge {
  padding-left: 80px !important;
}

.u-padding-left-none {
  padding-left: 0 !important;
}

.u-padding-horizontal {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.u-padding-horizontal-tiny {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.u-padding-horizontal-small {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.u-padding-horizontal-big {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.u-padding-horizontal-large {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.u-padding-horizontal-huge {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.u-padding-horizontal-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.u-padding-vertical {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.u-padding-vertical-tiny {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.u-padding-vertical-small {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.u-padding-vertical-big {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.u-padding-vertical-large {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.u-padding-vertical-huge {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.u-padding-vertical-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-margin {
  margin: 15px !important;
}

.u-margin-tiny {
  margin: 5px !important;
}

.u-margin-small {
  margin: 10px !important;
}

.u-margin-big {
  margin: 20px !important;
}

.u-margin-large {
  margin: 40px !important;
}

.u-margin-huge {
  margin: 80px !important;
}

.u-margin-none {
  margin: 0 !important;
}

.u-margin-top {
  margin-top: 15px !important;
}

.u-margin-top-tiny {
  margin-top: 5px !important;
}

.u-margin-top-small {
  margin-top: 10px !important;
}

.u-margin-top-big {
  margin-top: 20px !important;
}

.u-margin-top-large {
  margin-top: 40px !important;
}

.u-margin-top-huge {
  margin-top: 80px !important;
}

.u-margin-top-none {
  margin-top: 0 !important;
}

.u-margin-right {
  margin-right: 15px !important;
}

.u-margin-right-tiny {
  margin-right: 5px !important;
}

.u-margin-right-small {
  margin-right: 10px !important;
}

.u-margin-right-big {
  margin-right: 20px !important;
}

.u-margin-right-large {
  margin-right: 40px !important;
}

.u-margin-right-huge {
  margin-right: 80px !important;
}

.u-margin-right-none {
  margin-right: 0 !important;
}

.u-margin-bottom {
  margin-bottom: 15px !important;
}

.u-margin-bottom-tiny {
  margin-bottom: 5px !important;
}

.u-margin-bottom-small {
  margin-bottom: 10px !important;
}

.u-margin-bottom-big {
  margin-bottom: 20px !important;
}

.u-margin-bottom-large {
  margin-bottom: 40px !important;
}

.u-margin-bottom-huge {
  margin-bottom: 80px !important;
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-left {
  margin-left: 15px !important;
}

.u-margin-left-tiny {
  margin-left: 5px !important;
}

.u-margin-left-small {
  margin-left: 10px !important;
}

.u-margin-left-big {
  margin-left: 20px !important;
}

.u-margin-left-large {
  margin-left: 40px !important;
}

.u-margin-left-huge {
  margin-left: 80px !important;
}

.u-margin-left-none {
  margin-left: 0 !important;
}

.u-margin-horizontal {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.u-margin-horizontal-tiny {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.u-margin-horizontal-small {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.u-margin-horizontal-big {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.u-margin-horizontal-large {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.u-margin-horizontal-huge {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.u-margin-horizontal-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.u-margin-vertical {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.u-margin-vertical-tiny {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.u-margin-vertical-small {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.u-margin-vertical-big {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.u-margin-vertical-large {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.u-margin-vertical-huge {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.u-margin-vertical-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos] {
  pointer-events: none;
}

[data-aos].aos-animate {
  pointer-events: auto;
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@media screen {
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }
  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  html:not(.no-js) [data-aos=fade-up] {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down] {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-right] {
    -webkit-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-left] {
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }
  html:not(.no-js) [data-aos=fade-up-right] {
    -webkit-transform: translate3d(-100px, 100px, 0);
    transform: translate3d(-100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-up-left] {
    -webkit-transform: translate3d(100px, 100px, 0);
    transform: translate3d(100px, 100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-right] {
    -webkit-transform: translate3d(-100px, -100px, 0);
    transform: translate3d(-100px, -100px, 0);
  }
  html:not(.no-js) [data-aos=fade-down-left] {
    -webkit-transform: translate3d(100px, -100px, 0);
    transform: translate3d(100px, -100px, 0);
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }
  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }
  html:not(.no-js) [data-aos=zoom-in] {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(0.6);
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(0.6);
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(0.6);
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-in-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(0.6);
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  html:not(.no-js) [data-aos=zoom-out] {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos=zoom-out-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    visibility: hidden;
  }
  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  html:not(.no-js) [data-aos=slide-up] {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  html:not(.no-js) [data-aos=slide-down] {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  html:not(.no-js) [data-aos=slide-right] {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  html:not(.no-js) [data-aos=slide-left] {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  html:not(.no-js) [data-aos=flip-left] {
    -webkit-transform: perspective(2500px) rotateY(-100deg);
    transform: perspective(2500px) rotateY(-100deg);
  }
  html:not(.no-js) [data-aos=flip-left].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-right] {
    -webkit-transform: perspective(2500px) rotateY(100deg);
    transform: perspective(2500px) rotateY(100deg);
  }
  html:not(.no-js) [data-aos=flip-right].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }
  html:not(.no-js) [data-aos=flip-up] {
    -webkit-transform: perspective(2500px) rotateX(-100deg);
    transform: perspective(2500px) rotateX(-100deg);
  }
  html:not(.no-js) [data-aos=flip-up].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
  html:not(.no-js) [data-aos=flip-down] {
    -webkit-transform: perspective(2500px) rotateX(100deg);
    transform: perspective(2500px) rotateX(100deg);
  }
  html:not(.no-js) [data-aos=flip-down].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
}

.c-color-box {
  min-height: 100px;
  margin: 20px 0;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-styleguide-section {
  margin-top: 20px;
  padding: 30px 15px;
}

.c-styleguide-section .c-button {
  margin-bottom: 15px;
}

.c-styleguide-section .c-heading {
  margin-bottom: 15px;
}

.c-styleguide-section.c-expert-section {
  background: #fff;
}

.page-template-stylegiude {
  background: #0029d6;
  color: #171717;
}
