/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $global-body-font-family;
  font-size: 18px;
  font-weight: 400;
  /* [1] */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: relative;

  @include mq($until: medium) {
    font-size: 0.875em;
    /* 14px */
  }
}

body {
  overflow-x: hidden;
  position: relative;
  padding-top: 125px;

  @include transition(padding);

  &.single-post>main {
    background: color-var(bg, 2);
    color: color-var(font, 2);
    @include mq($until: medium) {
      margin-top: -55px;
    }
    @include mq($from: medium) {
      text-align: justify
    }
  }

  &.is-scrolled {
    padding-top: 200px;
  }
}

.editor-block-list__layout {
  background: $color-bg;
  color: $color-text;
  font-family: $global-body-font-family;
  font-size: 18px;
  /* [1] */
  line-height: 1.5;
  overflow-x: hidden;
}


.post-type-post .editor-block-list__layout {
  background: color-var(bg, 2);
  color: color-var(font, 2);

  @include mq($from: medium) {
    text-align: justify
  }
}

.block-editor-block-list__layout .block-editor-default-block-appender>.block-editor-default-block-appender__content,
.block-editor-block-list__layout>.block-editor-block-list__block>.block-editor-block-list__block-edit,
.block-editor-block-list__layout>.block-editor-block-list__layout>.block-editor-block-list__block>.block-editor-block-list__block-edit {
  margin-top: 0;
  margin-bottom: 0;
}
