.c-contact-form {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: #e0fffc;

    @include mq($until: large) {
        padding-bottom: 30px;
    }

    &__title {
        line-height: 1.2;
        @include inuit-font-size($inuit-font-size-h3 - 6px);

        @include mq($until:medium) {
            @include inuit-font-size($inuit-font-size-h4 + 2px, $modifier: -0.8);
        }
    }

    &__content {
        line-height: 2;
    }

    &__anim {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -960px;
        width: 1920px;
        height: auto;
        z-index: -1;
        pointer-events: none !important;
        z-index: 100;
    
        @include mq($until: large) {
          display: none;
        }
    }

    &__content-wrap {
        padding: 5rem 0;

        @include mq($until: large) {
            padding: 2rem 0;
        }
    }

    &__content {
        color: color-var(font, 2);
    }

    &__form-wrap {
        position: relative;
        padding: 2em;

        &:before {
            content: '';
            display: block;
            background-color: color-var(bg);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: -1;
            width: 100%;
        }

        @include mq($from: large) {
            padding: 3rem 0;
            padding-left: 2em;
            max-width: 400px;

            &:before {
                width: 50vw;
            }
        }
    }

    &__form-title {
        line-height: 1.2;
    }
}