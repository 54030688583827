.c-score {
  background: color-var(bg, 2);
  color: color-var(font, 2);
  padding: 4rem 1.5rem 1.5rem 4rem;

  @include mq($until:medium) {
    padding: 6rem 1.5rem 1.5rem;
  }

  margin-bottom: 4.2rem;
  position: relative;
  box-shadow: 45px 45px 0px 0px rgba(color-var(bg, 2), 0.1);
  z-index: 1;

  &__logo {
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0.7;
  }

  &__number {
    @include inuit-font-size($inuit-font-size-h2);
    line-height: 1.5rem;
  }

  &__desc {
    max-width: 14.5rem;
    margin-top: 2.5rem;
    margin-bottom: 1.66rem;
  }

  &__anim {
    position: absolute;
    top: -25%;
    left: -25%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
    transform: scale(0);
    transition: all 2s linear;

    &.is-active {
      top: 0;
      left: 0;
      transform: scale(1);
    }
  }

  &--inline {
    @include mq($from:medium) {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      min-height: 128px;

      .c-score {
        &__number {
          padding: 0 60px 0 40px;
          font-size: $inuit-font-size-h3 - 4px;
          min-width: 260px;
        }

        &__desc {
          margin: 0;
          max-width: none;
          min-height: 1px;
        }

        &__anim {
          width: 450px;
          height: 250px;
          transform: scale(1);
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.c-scores {
  background: color-var(bg, 3);
  padding: 5.55rem 0;

  @include mq($until: medium) {
    padding-bottom: 30px;
  }

  position: relative;
  z-index: 1;

  &__container {
    padding: 5.55rem 0 4.5rem;
  }


  &__bg {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
    width: 1920px;
    height: auto;
    pointer-events: none;
    z-index: -1;
  }

  &__filter {
    &s {
      display: flex;
      margin: -150px 0 -10px;

      @include mq($until: large) {
        flex-wrap: wrap;
      }
      @include mq($until:medium) {
        margin:0;
      }
    }

    text-transform: uppercase;
    padding:16px 5px;
    line-height: 2rem;
    font-family: $global-heading-font-family;
    font-weight: 500;
    font-size: $inuit-font-size-h7;
    flex:1;

    @include mq($until: large) {
      flex: 0 0 50%;
    }

    border:1px solid color-var(border);
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;

    &.is-active,
    &:hover {
      background: color-var(bg, 4);
      color: color-var(font, 2);
    }

    @include mq($from: large) {
      &:not(:first-child) {
        border-left: 0;
      }
    }

    @include mq($until: large) {
      flex: 0 0 50%;
    }

    @include mq($until: medium) {
      flex: 0 0 100%;
    }
  }

  &--lower {

    padding: 80px 0 50px;

    .c-scores__container {
      padding-bottom: 0;
    }
  }
}
