.c-steps-tabs {
    color: color-var(font, 2);
    padding: 3rem 0;
    background: color-var(bg, 2);
    position: relative;
    z-index: 2;

    &__title {
        line-height: 1.2;
        @include inuit-font-size($inuit-font-size-h3 - 4px);

        @include mq($until:medium) {
            @include inuit-font-size($inuit-font-size-h4 + 2px, $modifier: -0.8);
        }
    }

    &__filter {
        &s {
          display: flex;
    
          @include mq($until: large) {
            flex-wrap: wrap;
          }
          @include mq($until:medium) {
            margin:0;
          }
        }
    
        padding: 16px 5px;
        font-family: $global-heading-font-family;
        font-weight: 500;
        font-size: $inuit-font-size-h7;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    
        @include mq($until: large) {
          flex: 0 0 50%;
        }
    
        border:1px solid color-var(border, 2);
        color: color-var(font, 3);
        text-align: center;
        cursor: pointer;
        transition: all 0.3s linear;
    
        &.is-active,
        &:hover {
          background: color-var(bg, 1);
          color: color-var(font, 1);
        }
    
        @include mq($from: large) {
          &:not(:first-child) {
            border-left: 0;
          }
        }
    
        @include mq($until: large) {
          flex: 0 0 50%;
        }
    
        @include mq($until: medium) {
          flex: 0 0 100%;
        }
    }

    &__container {
        @include mq($until: medium) {
            flex-direction: column;
        }
    }

    &__step {
        border-radius: 50%;
        background-color: color-var(bg, 4);
        color: color-var(font, 2);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1.5;
        padding: 1em;
        width: 11em;
        height: 11em;
        margin: .5em 1em;
        position: relative;
        font-family: $font-sans-serif;
        font-weight: 500;

        @include mq($until: medium) {
            margin: 1em;
        }
    }

    &__step:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: color-var(bg, 4);
      opacity: .1;
      z-index: -1;
      border-radius: 50%;
    }

    &__step:nth-child(2n+1), &__step:nth-child(2n+3) {
      &:before {
        transform: translate(-10%, 15%);

        @include mq($until: medium) {
          transform: translate(-10%, 10%);
        }
      }
    }

    &__step:nth-child(2n+2) {
      &:before {
        transform: translate(-10%, -15%);

        @include mq($until: medium) {
          transform: translate(-10%, -10%);
        }
      }
    }

    &__step:nth-child(2n+4) {
      &:before {
        transform: translate(10%, -15%);

        @include mq($until: medium) {
          transform: translate(10%, -10%);
        }
      }
    }

    &__step:nth-child(2n+5) {
      &:before {
        transform: translate(10%, 15%);

        @include mq($until: medium) {
          transform: translate(10%, 10%);
        }
      }
    }

    &__step:not(:last-child):after {
        content: '';
        position: absolute;
        top: 50%;
        right: -1em;
        transform: translate(50%, -50%);
        width: 0; 
        height: 0; 
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 15px solid color-var(bg, 5);

        @include mq($until: medium) {
            top: inherit;
            bottom: -1em;
            right: 50%;
            transform: translate(50%, 50%);
            border: 0;

            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-top: 15px solid color-var(bg, 5);
        }
    }

    &__step p {
        margin-bottom: 0;
    }

    &__text {
        font-family: $font-sans-serif;
        font-weight: 500;
        font-size: 16px;
    }

    &__table {

    }

    &__table-row {
      display: flex;
      border-top: 2px solid #d8d8d8;

      @include mq($until: medium) {
        flex-direction: column;
      }

      &:last-child {
        border-bottom: 2px solid #d8d8d8;
      }
    }

    &__table-title {
      color: color-var(font, 3);
      font-weight: 500;
      background-color: color-var(bg, 7);
      flex: 1;
      padding: 40px;

      @include mq($until: medium) {
        padding: 20px;
      }
    }

    &__table-content {
      padding: 40px;
      flex: 2;
      font-size: 0.9em;

      @include mq($until: medium) {
        flex: 1;
        padding: 20px;
      }
    }
}