/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.c-heading {
  position: relative;
  font-weight: 500;
  color: color-var(font, 2);
  font-family: $global-heading-font-family;
  margin-bottom: 0;
  z-index: 1;

  &--hero {
    @include inuit-font-size($inuit-font-size-h1, $modifier: 1);

    @include mq($until:medium) {
      @include inuit-font-size($inuit-font-size-h3 - 1.5px, $modifier: -0.3);
    }
  }

  &--primary {
    @include inuit-font-size($inuit-font-size-h2, $modifier: -0.4);

    @include mq($until:medium) {
      @include inuit-font-size($inuit-font-size-h3 - 9px, $modifier: -0.8);
    }
  }

  &--secondary {
    @include inuit-font-size($inuit-font-size-h3, $modifier: 1.6);

    @include mq($until:medium) {
      @include inuit-font-size($inuit-font-size-h4 + 2px, $modifier: -0.8);
    }
  }

  &--blog {
    @include inuit-font-size($inuit-font-size-h3 - 6px, $modifier: 1.6);
  }

  &--tertiary {
    @include inuit-font-size($inuit-font-size-h4, $modifier: -0.8);
  }

  &--with-line {
    padding-bottom: 1em;
    margin-bottom: 1.8em;
    @include line(100px);

    &-2 {
      &:after {
        background: color-var(bg);
      }
    }
  }
}
