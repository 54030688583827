/* ==========================================================================
   #SELECT
   ========================================================================== */

.c-select {
  &__title {
    @include formInput();
    text-align: left;
    border: 0;
    border-bottom: 1px solid color-var(border, 5);
    padding: $inuit-global-spacing-unit 0;
    cursor: pointer;
    position: relative;
    @include arrowTick(true);
  }
  &__list {
    border: 1px solid color-var(border, 4);
    border-radius: $global-radius;
    padding: $inuit-global-spacing-unit-big;
    margin-top: -1px;
    position: relative;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: color-var(bg);
    &.is-open {
      opacity: 1;
      pointer-events: all;
    }
  }
  &__item {
    line-height: 2em;
    cursor: pointer;
    &:hover {
      color: color-var(font, 3);
    }
    &:first-child {
      display: none;
    }
    &.is-selected {
      color: color-var(font, 3);
    }
  }
  .u-disabled & {
    .c-select {
      &__title {
        border-style: dashed;
        pointer-events: none;
        color: color-var(font, 2);
        &:hover {
          border-color: color-var(border, 5)
        }
      }
    }
  }
}