.c-team {
  background: color-var(bg, 6);
  color: color-var(font, 2);
  padding: 5.55rem 0;

  &__desc {
    padding: 4rem 0 1.5rem;
  }

  &__triangle {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

}
