/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: color-var(bg, 3);

  &__logo {
    padding: 3.33rem 0;

    @include mq($until: medium) {
      padding-bottom: 15px;
    }
  }

  &__copy {
    padding: 4.22rem 0 3.33rem 0;
    margin: 0;
    opacity: 0.5;
    letter-spacing: 0.75px;

    @include mq($until: medium) {
      padding-top: 15px;
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
}
