.c-steps {
    padding: 2rem 0;

    &__item {
        text-align: center;
        padding: 1em;
    }

    &__item-content {
        line-height: 2em;
        font-size: .9em;
    }

    &__item-number {
        display: inline-block;
        margin-bottom: 1em;
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        background-color: color-var(bg, 4);
        color: $color-black;
        font-size: 1.25rem;
        line-height: 2.65em;
        font-family: $font-sans-serif;
        font-weight: 500;
    }
}