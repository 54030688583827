.c-results {
  overflow: hidden;
  padding: 3.5rem 0 5rem;

  &__content {
    @include mq($until:large) {
      position: relative;
      z-index: 2;
      background: color-var(bg, 2);
      box-shadow: 50vw -50vw 0px 50vw color-var(bg, 2);
    }

    padding-right: 30px;
  }

  &__form {
    padding-left: 4.8rem !important;
    background: color-var(bg);
    box-shadow: 50vw 0px 0px 50vw color-var(bg);

    @include mq($until:large) {
      padding-top: 40px;
      padding-left: 30px !important;
    }
  }
}
