.c-offer {
  margin-bottom: 3rem;

  @include mq($until:medium) {
    text-align: center;
  }

  &s {
    color: color-var(font, 2);
    padding: 5rem 0;
    background: color-var(bg, 7);
    position: relative;
    z-index: 2;

    &__desc {
      line-height: 2em;
    }
  }

  &__anim {
    position: absolute;
    top: 0;
    left: -10px;
    width: 400px;
    height: 200px;
    pointer-events: none;
    z-index: 1;

    @include mq($until:medium) {
      width: 200%;
      height: 160px;
    }
  }

  &__ico {
    margin-bottom: 3.5rem;
    height: 155px;
    padding: 38px 0 0 15px;
    position: relative;

    img {
      position: relative;
      z-index: 2;
    }

    @include mq($until:medium) {
      width: 50%;
      margin: auto;
    }
  }

  &__title {
    font-family: $global-heading-font-family;
    font-weight: 500;
    margin-bottom: 1em;
  }

  &__desc {
    color: #616161;
  }

  &__container {
    margin-bottom: 2rem;
  }

  &--inline {
    @include mq($from: medium) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0rem;
    }

    .c-offer__ico {
      width: 210px;
      flex: 0 0 210px;
    }
  }

  &--single {
    align-items: center;

    .c-offer {
      &__desc {
        color: inherit;
        padding: 15px 0;
      }

      &__subtitle {
        color: #757575;
        font-family: $global-heading-font-family;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      &__ico {
        width: 155px;
        margin-bottom: 40px;

        @include mq($from:large) {
          margin: auto;
          margin-bottom: 0;
        }
      }
    }
  }
}

.c-single-offer {
  padding: 50px 0;

  @include mq($from:large) {
    padding: 120px 0;
  }

  &--right {
    .c-offer--single>div:first-child {
      @include mq($from:large) {
        order: 2;
      }
    }
  }
}
