/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

.wp-block-quote {
  font-style: italic;
  background: url(../assets/images/quote-blue.svg) no-repeat top 5px left;
  padding-left: 70px !important;
  border: 0;
}

.wp-block-pullquote {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-style: solid;
  border-color: #d2d2d2;
  padding: 20px 30px !important;
  font-size: 0.89rem !important;
  line-height: 2rem;
  text-align: left !important;
  color: #565656;

  * {
    margin: 0 !important;
    font-size: 0.89rem !important;
    line-height: 2rem !important;
  }
}
