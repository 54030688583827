/* ==========================================================================
   #Table
   ========================================================================== */

.c-table {
  &-section {
    padding: 75px 0 100px;
  }

  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  font-family: $global-heading-font-family;
  font-weight: 500;
  margin-top: 75px;

  th {
    padding: 40px 15px;
    font-weight: 500;
    background: color-var(bg, 7);
    border-bottom: 2px solid color-var(border, 2);
  }

  td {
    padding: 38px 15px;

    border-bottom: 1px solid #d6d6d6;

    &:nth-child(1) {
      background: color-var(bg, 7);
    }

    &:nth-child(2) {
      font-weight: 400;
    }
  }

  @include mq($until:medium) {
    min-width: 768px;
  }

  &__container {
    @include mq($until:medium) {
      overflow-x: scroll;
    }
  }
}
