.c-clients {
  padding: 6.44rem 0 0;
  background: darken(color-var(bg, 3), 1.5%);
  position: relative;

  &__logo {
    padding: 20px 0;
    text-align: center;
    margin-top: 1em;

    &s {
      padding: 3em 0 4em;
      margin: 0;
      @include mq($until: medium) {
        padding: 3em 0 0;
      }
    }

    @include mq($until: medium) {
      padding: 10px !important;
      padding-bottom:0!important;
    }
  }

  &__anim {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
  }

}
