.c-section {
  @include mq($until: medium) {
    padding: 30px 0 !important;
   &:first-of-type{
     margin-top:-55px;
   }
    &.c-post-archive-header{
      padding:0!important;
    }
  }
}
