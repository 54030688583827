/* ==========================================================================
   #FORM
   ========================================================================== */

.c-form {
  padding: 0 0 5rem;

  @include mq($from: medium) {
    padding: 4rem 0 5rem;
  }

  @include mq($until: medium) {
    &.c-section {
      padding-bottom: 0 !important;
      &:first-of-type{
        margin-top:-55px;
      }
    }
  }

  position: relative;
  z-index: 1;
  overflow: hidden;
  background: color-var(bg);

  &__anim {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
    width: 1920px;
    height: auto;
    z-index: -1;
    pointer-events: none !important;
    z-index: 100;

    @include mq($until: large) {
      display: none;
    }
  }

  &__title,
  &__subtitle {
    padding: 0 0 3rem;
  }

  &__subtitle {
    line-height: 2rem !important;
  }

  &__quotes {
    background: color-var(bg, 3);
    position: relative;



    .c-form__subtitle {
      @include mq($from: medium) {
        padding-left: 70px;
      }
    }

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -120px;
      left: 0;
      height: calc(100% + 300px);
    }

    &:before {
      width: 50vw;
      background: color-var(bg, 3);
      z-index: -1;
    }

    &:after {
      width: 30px;
      background: color-var(bg);
    }

    @include mq($until: large) {
      padding: 50px;
      padding-bottom: 100px;
      margin-top: 2rem;

      .c-slider__pager {
        bottom: 50px;
      }

      &:before,
      &:after {
        display: none;
      }
    }

    @include mq($until:medium) {
      flex: 0 0 calc(100% + 30px) !important;
      width: calc(100% + 30px);
      max-width: calc(100% + 30px) !important;
    }
  }

  &__slider {
    position: relative;

    &-pager {
      margin: auto;
      left: 100px;
      right: 0;
      text-align: center;
      z-index: 1000 !important;
      position: relative;

      .c-slider__page {
        margin-left: 70px
      }
    }
  }


  &__data {
    @include mq($from:medium) {
      padding-left: 70px;
    }

    &-small {
      padding-top: 2em;
      opacity: 0.5;
      @include inuit-font-size($inuit-font-size-h7);
      line-height: 2em;
    }
  }

  &__more-info {
    display: none;

    &.is-active {
      display: inline;
    }
  }

}

.wpcf7-form-control-wrap.acceptance-20 {
  @include inuit-hidden-visually();
}

.c-filelist {
  margin: 0;

  &__label {
    padding-top: 0 !important;
  }

  &__item {
    display: flex;
    align-items: center;
    @include inuit-font-size($inuit-font-size-h6);
    padding: 10px 14px;
    line-height: 1.5em;
    border: 1px solid rgba(color-var(border, 5), 0.15);
    margin-top: 10px;
    position: relative;
  }

  &__delete {
    color: color-var(font, 5);
    @include inuit-font-size($inuit-font-size-h3);
    line-height: 24px;
    height: 16px;
    // padding-top: 5px;
    margin-left: 10px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
  }

}

.grecaptcha-badge {
  z-index: 99999999999;
}
