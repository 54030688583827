.c-hero {
  padding-bottom: 11.1rem;

  @include mq($until: large) {
    padding-bottom: 2rem;
  }

  @include mq($until: medium) {
    &.c-section {
      padding-bottom: 0 !important;
    }
  }


  @include mq($until: medium) {
    padding-bottom: 0;
  }

  position: relative;

  &__title {
    margin-top: 1.5em;
    margin-bottom: 0.83em;

    @include mq($until: large) {
      margin-top: 0.83em;
    }
  }

  &__desc {
    line-height: 2em;
    margin-bottom: 1em;

    @include mq($until: medium) {
      br {
        display: none;
      }
    }
  }

  &__graph {
    position: absolute;
    width: calc(100% + 16px);
    // width: 1920px;
    height: auto;
    bottom: -4px;
    right: -10px;
    z-index: -1;

    circle,
    line {
      position: relative;
    }

    circle {
      z-index: 1;
    }

    line {
      z-index: 0;
    }

    @include mq($until: medium) {
      width: 200%;
      bottom: -15px;
      left: -80%;
      position: relative;
    }
  }

  &__triangles {
    position: absolute;
    width: 1920px;
    top: 0;
    left: 50%;
    margin-left: -960px;
    pointer-events: none;
    z-index: 9999;

    @include mq($until: medium) {
      top: -43px;
      display:none;
    }

    .c-triangle {
      opacity: 0;
      transition: opacity ease-in-out 0.5s;

      &.is-active {
        opacity: 1;
      }


    }

    @include mq($until: full) {
      .c-triangle:nth-of-type(2) {
        display: none;
      }

    }
  }

  &__logo-text {
    max-width: 300px;
    border-left: 1px solid #fff;
    padding: 10px;
  }
}
