/* ==========================================================================
   #breadcrumbs
   ========================================================================== */

.c-breadcrumbs {
  @include inuit-font-size($inuit-font-size-h7);
  opacity: 0.5;
  padding: 2em 0;

  &__container {
    position: absolute;
    top: 125px;
    left: 0;
    width: 100%;
    z-index: 1000;

    @include mq($until:medium) {
      top: 55px;
    }
  }

  &--alternative {
    color: color-var(font, 2);
  }

  .page-id-28 & {
    color: color-var(font, 2);
  }
}
