/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post-archive {
  padding: 70px 0 85px;

  .c-tease {
    margin-top: 0;
  }

  &__content {
    padding-top: 65px;
    overflow: hidden;
  }

  &__button {
    padding-top: 75px;
    margin-bottom: 0;
  }
}

@include mq($until: medium) {
.post-type-archive{
  &-offer{
    .c-page-header__image-container{
      display: none;
    }
    .c-page-header__title{
      margin-top:auto;
    }

  }
}
  .c-page-header__title:first-of-type{
    margin-top:auto;
  }

}
