.c-page-header {
  padding-bottom: 5rem;
  position: relative;
  overflow: hidden;

  background: color-var(bg, 3);

  @include mq($until:medium) {
    padding-top: 0 !important;
  }

  &__anim {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
    width: 1920px;
    height: auto;
    z-index: -1;
    pointer-events: none !important;
    z-index: 100;
  }

  &__title {
    margin-top: 1.5em;
    margin-bottom: 0.83em;

    @include mq($until:medium) {
      // margin-top: 3em;
    }
  }

  &__desc {
    line-height: 2em;
    margin-bottom: 3em;

    @include mq($until:medium) {
      margin-bottom: 0;
    }
  }

  &__image {
    position: relative;
    z-index: 150;

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;

      @include mq($until:medium) {
        order: -1;
        margin-top: 1rem;
      }

      body.error404 & {
        min-height: 630px;
      }
    }
  }

  &--static {
    padding-top: 50px;
  }
}

.c-content {
  position: relative;
  z-index: 2;
  border-top: 1px solid #d2d2d2;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0;
  color: #5C5C5C;
  font-size: 16px;

  &>div:first-child {
    padding-left: 0;
    padding-right: 30px;
  }

  &__subtitle {
    font-size: 14px;
  }

  &--bg {
    background: color-var(bg, 7);
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -75px;
      right: 0;
      background: color-var(bg, 7);
      height: 200vh;
      width: 50vw;
      z-index: -1;
    }
  }

  &--1 {
    @include mq($until:medium) {
      border: 0;
    }
  }

  &__button {

    z-index: 100;
    position: relative;
  }
}

#promo-banner {
  padding-bottom: 0;

  .c-page-header {
    &__title {
      font-size: 40px !important;
    }

    &__anim {
      transform: scale(0.6) translate(12%, 0);
      transform-origin: top center;
    }
  }
}
