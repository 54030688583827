// Cookie
.kg-cookie-content {
  position: fixed;
  bottom: 0;
  text-align: center;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(#000, 0.8);
  z-index: 9999999;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    padding: 5px;

    #cookieAccept,
    #cookieNoAccept {
      z-index: 9999;
    }
  }

  p {
    margin: 0;
  }
}

//gdpr
#kg_gdpr.modal {
  display: none;
  position: fixed;
  left: 15px;
  bottom: 78px;
  overflow: auto;
  z-index: 999999999;

  .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 300px;
    z-index: 9999999;
    color: #000;
    font-size: 12px;

    .gdpr_text {
      padding: 0 1em;
      a{
        text-decoration: underline;
      }
    }

    .kg_rodo_btns {
      text-align: center;
     .kg_btn {
       margin-bottom:10px;
     }
    }

    .close {
      color: rgb(255, 0, 80);
      font-size: 2.22222rem;
      line-height: 24px;
      height: 24px;
      padding-top: 5px;
      margin-left: 10px;
      cursor: pointer;
      position: absolute;
      top: 0!important;
      right: 0;


      &:hover,
      &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }

  }
}

.kg_btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0 5px;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 0.2rem 2.9rem;
  transition: $global-transition;
  @include inuit-font-size($inuit-font-size-h7);
  text-transform: uppercase;
  font-family: $global-heading-font-family;
  font-weight: 500;
  line-height: 2.5em;
  text-decoration: none;
  border: 0;
  border-radius: 0;
  transition: background-color 0.3s linear;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  background-color:#001fa3;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: color-var(bg, 2);
    color: color-var(font, 2);
  }

  &_accept {}

  &_noaccept {
    display: none;
  }
}

.kg_gdpr_popup #kg_gdpr {
  position: fixed!important;
  left: 2%!important;
  bottom: 2%!important;
  width: 300px;
  height: 370px;


  &.modal{
    .modal-content {
      width: auto;
      max-width: none;
      border: none;
      padding: 0 2% !important;
      padding-top:35px!important;
    }
  }
}

.gdpr_text{
  max-height:250px!important;
}


@media only screen and (max-width:768px) {
  #kg_gdpr.modal {
    left:auto!important;
    bottom:auto!important;
    position:fixed!important;
    top:24%;
    width:96%;
    margin-left:2%;
    margin-right:2%;


    .modal-content {
      max-height: 60vh;
      overflow: scroll;
      margin: 0% auto;
    }
  }

}
