.c-about {
  color: color-var(font);
  padding: 5rem 0;
  background: color-var(bg, 3);
  position: relative;
  z-index: 2;

  &__title {
    margin-bottom: 3rem;
    margin-bottom: 3rem;
  }

  &__desc {
    line-height: 2em;
  }

  &__item {
    &-desc {
      opacity: 0.7;
    }

    &s {
      align-content: space-between;
    }
  }

  &__container {
    margin: 5rem 0;
  }

  &__triangle {
    width: 100%;
    position: relative;
    z-index: 3;
    margin-top: 50px;
  }

  &__image {
    &s {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 500px;
    }

    position: absolute;
    right:0;
    height: calc(50% - 15px);
    width: auto;
    max-width: 100%;

    &--1 {
      top: 0;
    }

    &--2 {
      bottom: 0
    }
  }

  &__price {
    @include inuit-font-size($inuit-font-size-h7);

    @include mq($until: medium) {
      &-desc.o-flex {
        flex-wrap: wrap;
      }
    }

    img {
      margin-bottom: 15px;
    }

    @include mq($until: medium) {
      .c-about & {
        text-align: center;
      }
    }
  }
}
