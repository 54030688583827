/* ==========================================================================
   #LABEL
   ========================================================================== */

.c-label {
  // position: relative;
  padding-top: $inuit-global-spacing-unit-tiny;
  display: block;

  &__title {
    color: color-var(font, 4);
    font-weight: 500;
    font-family: $global-heading-font-family;
    @include inuit-font-size($inuit-font-size-h6);
    line-height: 1.5em;
    margin-bottom: 10px;
    display: inline-block;

    @include mq($until: medium) {
      font-size: 16px;
    }

  }

  &__error {
    @include inuit-font-size($inuit-font-size-h6);
    line-height: 1.2em;
    margin-top: $inuit-global-spacing-unit-small;
    display: inline-block;
    color: color-var(font, 7);
  }

  &--required {
    .c-label__title {
      &:after {
        content: "*"
      }
    }
  }

  &--checkbox,
  &--radio {
    .c-label__title {
      position: static;
      color: color-var(font);
      font-weight: 400;
      @include inuit-font-size($inuit-font-size-h7);
      line-height: 1.5em;
    }
  }

  &--inline {
    display: inline-block;
    margin-right: $inuit-global-spacing-unit-small;

    &.c-label--checkbox,
    &.c-label--radio {
      margin-bottom: 0;
      padding-top: 0;

      .c-label__title {
        @include inuit-font-size($inuit-font-size-h5);
        line-height: 34px;
        margin-bottom: 0;
      }
    }
  }

  &--bigger {
    @include inuit-font-size($inuit-font-size-h6, auto, 0, true);
    color: color-var(font, 4);
  }
}
