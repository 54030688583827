.c-slider {
  &__container {
    position: relative;
    align-self: stretch;
  }

  &__pager {
    position: absolute;
    bottom: -60px;
    right: 30px;
    z-index: 5;

    &--alternative {
      .c-slider__page {
        background: color-var(bg, 4);
      }
    }

    @include mq($until:medium) {
      left: 0;
      right: 0;
    }
  }

  &__page {
    @include square-style(10px);
    border-radius: 5px;
    background: color-var(bg);
    opacity: 0.3;
    display: inline-block;
    margin-left: 60px;
    cursor: pointer;
    transition: opacity linear 0.2s;

    &:first-child {
      margin-left: 0;
    }


    &.is-active {
      opacity: 1;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &--standard {
    display: flex;
    overflow: hidden;

    .c-slider {
      &__slide {
        flex: 0 0 100%;
        max-width: 100%;
        min-width: 100%;
        padding: 0 32px;

        &.is-active {
          order: -1;
        }
      }
    }
  }

  @for $i from 1 through 30 {
    &.c-slider--standard[data-slides-visible="#{$i}"] & {
      &__slide {
        flex: 0 0 #{100/$i * 1%};
        max-width: #{100/$i * 1%};
        min-width: #{100/$i * 1%};
      }
    }
  }

  @for $i from 1 through 30 {
    &.c-slider--standard[data-slides-visible-mobile="#{$i}"] & {
      @include mq($until: large) {
        &__slide {
          flex: 0 0 #{100/$i * 1%};
          max-width: #{100/$i * 1%};
          min-width: #{100/$i * 1%};
          padding: 0 20px;
        }
      }
    }
  } 

  &.c-slider--standard[data-slides-visible-mobile="1"] & {
    @include mq($until: large) {
      &__slide {
        padding: 0;
      }
    }
  }


  &--rotator {
    position: absolute;
    height: 100%;
    width: 100%;

    .c-slider {

      &__slide {
        position: absolute;
        margin: auto;
        opacity: 0;
        pointer-events: none;
        height: 50%;
        width: 50%;
        display: flex;

        justify-content: flex-end;
        transition: 0.3s linear opacity;

        &:nth-child(3n+1) {
          right: calc(50% + 17px);
          bottom: calc(50% + 17px);
          z-index: 1;
          align-items: flex-end;
        }

        &:nth-child(3n+2) {
          left: calc(50% + 17px);
          bottom: 0;
          top: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          height: 100%;
          justify-content: flex-start
        }

        &:nth-child(3n) {
          right: calc(50% + 17px);
          top: calc(50% + 17px);
          z-index: 3;
          align-items: flex-start;
        }

        &.is-active {
          opacity: 1;
          pointer-events: all;
        }

        img {
          max-height: 100%;
        }
      }
    }
  }

  &--alternative {
    background: color-var(bg);
    height: 100%;
    padding: 70px 0;
    position: relative;
    z-index: 1;
    overflow: visible;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -75px;
      left: 0;
      background: color-var(bg);
      height: calc(100% + 150px);
      width: 50vw;
      z-index: -1;

      @include mq($until:large) {
        width: 100vw;
        left: -30px;
      }
    }

    &:after {
      left: 100%;
      z-index: 1;
    }
  }

  &__subtitle {
    padding-left: 70px;
    position: relative;
    z-index: 1000;
  }
}

@include mq($until:medium) {
  .c-slider-alternative__container {
    margin: 30px 0;
  }
}
