/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: color-var(bg);
  z-index: 99999999;

  &__wrapper {
    height: 125px;
    @include transition(height);
  }

  @include mq($until: medium) {
    background: color-var(bg, 3);

    &__wrapper {
      height: 70px;
    }
  }


  &.is-scrolled {

    .c-header__wrapper {
      height: 70px;

      img {
        height: 30px;
      }
    }
  }

  &__nav {
    @include mq($until: medium) {
      position: absolute;
      top: 100%;
      left: -100%;
      width: 100%;
      z-index: 9999;
      background: color-var(bg, 2);
      color: color-var(font, 2);
      justify-content: center;
      padding: 30px 0;
      transition: left linear 0.3s;
      height: calc(100vh - 70px);

      &.is-active {
        left: 0;
      }
    }

    &--trigger {
      line {
        pointer-events: none;
      }
    }
  }
}
